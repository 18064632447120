import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import useMonthDetailContext from "../../../hooks/useMonthDetailContext";
import Moment from 'react-moment';
import 'moment-timezone';
import Pagination  from 'react-js-pagination';
import { ITEMS_COUNT_PER_DETAIL_PAGE, PAGE_RANGE_DISPLAYED, curtailmentStatus, PREFERRED_TIMEZONE } from '../../../util/Constants';
import MonthlyReport from '../report/MonthlyReport';
import { thousandsSeparators } from '../../../util/CommonFunctions';
import useAuthContext from "../../../hooks/useAuthContext";

export default function DataSet() {
  const { t } = useTranslation("common");
  const { monthDetailData } = useMonthDetailContext();
  const [ eventDetailData, setEventDetailData ] = useState(monthDetailData.no_of_events);
  const [ totals, setTotalData ] = useState([]);
  
  const [activePage, setActivePage] = useState(1);
  const [totalRowsCount, setTotalRowsCount] = useState(monthDetailData.no_of_events.length);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(ITEMS_COUNT_PER_DETAIL_PAGE);
  const { name, email } = useAuthContext();
  const getStatusLabel = (statusKey) => {    
    let status = curtailmentStatus.filter((obj)=>{
      if (obj.key === statusKey) {
          return obj.label
      }
    })
      
    if (status.length > 0) {
      return status[0].label;
    }

    return statusKey;
  }

  useEffect(()=>{
    let totals = [];
    let newTotalUEObject = {};
    let newTotalAEObject = {};
    let newTotalDEObject = {};
    let UE = 0;
    let applicablePeriod = monthDetailData.month_name.split('-');

    newTotalUEObject['label'] = 'Total Undelivered Energy';
    newTotalUEObject['value'] = monthDetailData.undelivered_energy;
    newTotalUEObject['month'] = applicablePeriod[1];
    newTotalUEObject['year'] = applicablePeriod[0];

    totals.push(newTotalUEObject);

    newTotalAEObject['label'] = 'Actual Energy';
    newTotalAEObject['value'] = monthDetailData.actual_energy;
    newTotalAEObject['month'] = applicablePeriod[1];
    newTotalUEObject['year'] = applicablePeriod[0];
    totals.push(newTotalAEObject);

    newTotalDEObject['label'] = 'Deemed Energy';
    newTotalDEObject['value'] = monthDetailData.deemed_energy;
    newTotalDEObject['month'] = applicablePeriod[1];
    newTotalUEObject['year'] = applicablePeriod[0];
    totals.push(newTotalDEObject);
    setTotalData(totals);
  }, []);

  const getStartEndDate = (applicablePeriod) => {
      let period = applicablePeriod.split('/');
      return [
        period[0],
        period[1]
      ]
  }

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    setStartIndex((pageNumber-1)*ITEMS_COUNT_PER_DETAIL_PAGE);
    setEndIndex(pageNumber*ITEMS_COUNT_PER_DETAIL_PAGE);
  }

  return (
    <div className="UL-outer-setting">
      <div className="ULd-flex ULjustify-content-between ULalign-items-center">
        <div className="ulPd5rem">
          <h3>{t("dataset-app.page-heading")}</h3>
        </div>
        <div className="ulPd5rem">
          <button
            className="ULbtn btn-ul-red UL-outlinedSizemedium"
            style={{ cursor: "pointer" }}
            onClick={()=>MonthlyReport({
              overviewData : {
                'deemed_energy': monthDetailData.deemed_energy,
                'actual_energy': monthDetailData.actual_energy,
                'undelivered_energy': monthDetailData.undelivered_energy
              },
              eventDetailData : eventDetailData,
              startDate: monthDetailData.start_date,
              name:name,
              email:email,
              total_oprtr_shut : monthDetailData.total_oprtr_shut,
              total_oprtr_curt :  monthDetailData.total_oprtr_curt,
              total_owner_shut :  monthDetailData.total_owner_shut,
              total_owner_curt :  monthDetailData.total_owner_curt,
              total_unpln_shut : monthDetailData.total_unpln_shut
            })}
          >
            {t("dataset-app.download-report")}
          </button>
        </div>
      </div>

      <div className="ul-row" style={{marginRight:0}}>
        {totals.map((total) => (
          <div className="ul-col-md-4 resp-miniclass padding-card">
            <div className="ex-small-card">
              <div className="card-body">
                <div className="justify-content-between">
                  <p className="s-heading fs-1rem">{total.label}</p>
                  <div className="d-flex f-left" style={{wordBreak: 'break-word', width: '67%' }}>                    
                    <h3>{total.value} kWh</h3>                    
                  </div>
                  <div className="self-center f-right">
                    <div className="icon-circle"></div>
                  </div>
                  <div className="mb-0 UL-clearBoth">
                      <span className="month-text mr-15rem ">
                        {t("dataset-app.month")} :{" "}
                        <span className="fw-n cl000">{total.month}</span>
                      </span>
                    </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* TABLE OF EXISITING DATASETS */}
      <h5 className="UL-mg-l-10">{t("dashboard-app.uploadedData-heading")}</h5>
      <div
        className="UL-card-width-extralarge UL-mg-b-20 UL-pos-relative"
        style={{ marginLeft: "5px", maxWidth: "98.9%" }}
      >
        <div className="medium-card ul-cardHight" style={{ maxHeight: "100rem" }}>
          <div className="card-body">
            <div className="ULtabs-section ul-minHeight">
              <div className="ULtab content1 tabledataR">
                <div className="Table" role="table">
                  <div className="Heading" role="rowgroup">
                    <div className="Cell" role="row">
                      <p>{t("dataset-app.table-startDate")}</p>
                    </div>
                    <div className="Cell" role="row">
                      <p>{t("dataset-app.table-endDate")}</p>
                    </div>
                    <div className="Cell" role="row">
                      <p>{t("dataset-app.table-status")}</p>
                    </div>
                    <div className="Cell" role="row">
                      <p>{t("dataset-app.table-undelivered")}</p>
                    </div>
                    <div className="Cell" role="row">
                      <p>{t("dataset-app.table-deemed")}</p>
                    </div>
                    <div className="Cell" role="row">
                      <p>{t("dataset-app.table-actual")}</p>
                    </div>
                  </div>
                  {eventDetailData.slice(startIndex, endIndex).map((data) => (
                    <div className="table-Row" role="rowgroup">
                      <div to="evts-overview" className="Cell" role="row">
                        <p><Moment format="DD/MM/YY HH:mm" tz={PREFERRED_TIMEZONE}>{data.displayPorStart}</Moment></p>
                      </div>
                      <div to="evts-overview" className="Cell" role="row">
                        <p><Moment format="DD/MM/YY HH:mm" tz={PREFERRED_TIMEZONE}>{data.displayPorEnd}</Moment></p>
                      </div>
                      <div className="Cell" role="row">
                        <p>{getStatusLabel(data.status)}</p>
                      </div>
                      <div className="Cell" role="row">
                        <p>{data.undeliveredEnergy ? thousandsSeparators(data.undeliveredEnergy.toFixed(2)) : 0}</p>
                      </div>
                      <div className="Cell" role="row">
                        <p>{data.deemedEnergy ? thousandsSeparators(data.deemedEnergy.toFixed(2)) : 0}</p>
                      </div>
                      <div className="Cell" role="row">
                        <p>{data.actualEnergy ? thousandsSeparators(data.actualEnergy.toFixed(2)) : 0}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div
              id="myDIV2"
              className="f-right"
              style={{ marginTop: "10px" }}
            >
              {
                eventDetailData.length ?                  
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={ITEMS_COUNT_PER_DETAIL_PAGE}
                    totalItemsCount={totalRowsCount}
                    pageRangeDisplayed={PAGE_RANGE_DISPLAYED}
                    onChange={handlePageChange.bind(this)}
                    innerClass="UL-pagination ULcltextfff"
                    activeClass="active2"
                    itemClass="pagn2 f-left UL-mg-r-5"
                    linkClass="cl000"
                  />
                  :
                  null
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
