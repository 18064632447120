import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import useAuthContext from "../hooks/useAuthContext";

function PrivateRoute({ component: Component, path, ...rest }) {
    const { authTokens, isAdmin } = useAuthContext();
    if (authTokens && !isAdmin && path == '/files') {
        return(
            <Route 
                {...rest} 
                render = {(props) => <Redirect to="/dashboard" />}
            />
        );
    }
    
    return(
        <Route 
            {...rest} 
            render = {(props) => authTokens ? (<Component {...props} />) : <Redirect to="/login" />}
        />
    );
}

export default PrivateRoute;