import React from "react";
import { useTranslation } from "react-i18next";
import Aux from "../../hoc/Auxiliary/Auxiliary";
import Intro from "../../util/Intro";
import DashboardApp from "../../components/app/dashboard";

const Dashboard = () => {
  const { t, i18n } = useTranslation("common");
  const page = "dashboard";
  return (
    <Aux>
      <Intro page={page} />
      <DashboardApp />
    </Aux>
  );
};
export default Dashboard;
