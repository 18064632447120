import React/* , { useState } */ from "react";
import "./SignUp.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from 'yup';
import Aux from '../../../hoc/Auxiliary/Auxiliary';
//import { SERVER_URL } from '../../../util/Constants';
import { Link} from 'react-router-dom';

const SignUp = () => {

        //const [message, setMessage] = useState();

        return (            
                <Formik
                initialValues={{
                    name: '',
                    email: '',
                    password: '',
                    confirmPassword: ''
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string()
                        .required('Name is required'),
                    email: Yup.string()
                        .email('Email is invalid')
                        .required('Email is required'),
                    password: Yup.string()
                        .min(6, 'Password must be at least 6 characters')
                        .required('Password is required'),
                    confirmPassword: Yup.string()
                        .oneOf([Yup.ref('password'), null], 'Passwords must match')
                        .required('Confirm Password is required')
                })}
                onSubmit={fields => {
                    alert('SUCCESS!! :-)\n\n' + JSON.stringify(fields, null, 4));
                    /* setMessage({
                        data: "Registration is in progress...",
                        type: "alert-warning",
                      });

                    fetch(`${SERVER_URL}/user/register`, {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify(fields),
                    })
                    .then((res) => res.json())
                    .then((data) => {
                        const hasError = "error" in data && data.error != null;
                        setMessage({
                        data: hasError ? data.error : "Registered successfully",
                        type: hasError ? "alert-danger" : "alert-success",
                        });
                
                        !hasError && e.target.reset();
                    }); */
                }}
                render={({ errors, status, touched }) => (
                    <Aux>
                        <div className="UL-login-text ulError-color">Sign Up</div>
                        <Form className="UL-padding43rem">
                            <div className="form-group mb-3">
                                <label htmlFor="name" className="label-name dIB">Name</label>
                                <Field name="name" placeholder="Enter name" type="text" className={'UL-form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                                <ErrorMessage name="name" component="div" className="ulError-color" />
                            </div>
                            <div className="form-group  mb-3">
                                <label htmlFor="email" className="label-name dIB">Email</label>
                                <Field name="email" placeholder="Enter your Email Id" type="text" className={'UL-form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                <ErrorMessage name="email" component="div" className="ulError-color" />
                            </div>
                            <div className="form-group  mb-3">
                                <label htmlFor="password" className="label-name dIB">Password</label>
                                <Field name="password" placeholder="Enter password" type="password" className={'UL-form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                <ErrorMessage name="password" component="div" className="ulError-color" />
                            </div>
                            <div className="form-group  mb-3">
                                <label htmlFor="confirmPassword" className="label-name dIB">Confirm Password</label>
                                <Field name="confirmPassword" placeholder="Enter confirm Password" type="password" className={'UL-form-control' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')} />
                                <ErrorMessage name="confirmPassword" component="div" className="ulError-color" />
                            </div>
                            
                                <button type="submit" className="ULbtn btn-ul-red UL-wd-45p cr-pointer">Register</button>
                                <button type="submit" className="ULbtn line-gray UL-wd-45p cr-pointer UL-mg-l-15">Reset</button>
                                <div className="UL-signup-text">Already a member ?   <span> <Link to="/login" className="ulError-color"> Login In</Link> </span> </div>
                        </Form>
                    </Aux>                    
                )}
            />
        );
}
export default SignUp;