export const SERVER_URL = process.env.REACT_APP_SERVER_URL;

// This url is relative to the server as viewed by the browser (for now)
export const API_BASE_URL = SERVER_URL;
export const LANDING_PAGE = 'dashboard';
export const APPLICATION_NAME = "Undelivered Energy and Wind Farm Power Curve for the Dhofar Wind Farm";

/**INTERVAL FOR STABLE AND UNSTABLE */
export const STABLE_INTERVAL = 15*60*1000;
export const UNSTABLE_INTERVAL = 20*1000;

/**REFRESH INTERVAL TIME */
export const STOP_INTERVAL = 60*60*1000;
export const REFRESH_INTERVAL = 15*1000;

/**Configuration for Pagination */
export const ITEMS_COUNT_PER_PAGE = 8;
export const ITEMS_COUNT_PER_DETAIL_PAGE = 10;
export const PAGE_RANGE_DISPLAYED = 3;

/** Curtailment status */
export const curtailmentStatus = [
    {key: 'uncurtailed', label: 'Uncurtailed'},
    {key: 'ownerCurtailment', label: 'Owner Curt'},
    {key: 'ownerShutdown', label: 'Owner Shut'},
    {key: 'operatorCurtailment', label: 'Oprtr Curt'},
    {key: 'operatorShutdown', label: 'Oprtr Shut'},
    {key: 'unplannedShutdown', label: 'Unpln Shut'},
];

export const PREFERRED_TIMEZONE = "UTC";
