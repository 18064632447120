import React, {useEffect, useState} from 'react';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from 'yup';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import {useTranslation} from "react-i18next";
import useToastContext from "../../hooks/useToastContext";
import useAuthContext from "../../hooks/useAuthContext";
import useMonthDetailContext from '../../hooks/useMonthDetailContext';
//import LanguageSelector from '../../components/LanguageSelector/LanguageSelector';
//import Select from 'react-select'

const MyAccount = () => {
    const {t, i18n} = useTranslation('common');
    const { showToast, showToastSuccess, showToastError, hideToast } = useToastContext();
    const { name, email } = useAuthContext();
    const { averageTo30Degree, set30Degree } = useMonthDetailContext();


    useEffect ( () => {
            document.getElementById("ULdefaultOpen").click();
        }        
    ,[])
    
    const ULopenTabs = (evt, TabNameUL) => {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("ULtabcontent-vertical");
        for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("ULVtablinks");
        for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.getElementById(TabNameUL).style.display = "block";
        evt.currentTarget.className += " active";
    }
    const accountStatusOptions = [
        { value: 1, label: 'Active' },
        { value: 0, label: 'In Active' }
      ]
    const [selectedOption, setSelectedOption] = useState({ value: 1, label: 'Active' });
    const [ activeChangePassword, toggleChangePassword ] = useState(false);
    const [ activeAccountDetail, toggleAccountDetail ] = useState(false);
    const [fields, updateFields] = useState({
        name: name,
        email: email
    });

    return (
        <div style={{padding:"20px"}}>
            <div>
            <h5>{t('account.page-heading')}</h5>
            </div>
            <div className="UL-box-shadow">
            <div className="ULtab-vertical " style={{minHeight:"80vh"}}>
            <button type="button" className="ULVtablinks" style={{padding: "25px 10px"}} onClick={(e) => ULopenTabs(e, 'ULVtab1')} id="ULdefaultOpen">{t('account.account-summary')}</button>
            <button type="button" className="ULVtablinks" style={{padding: "25px 10px"}} onClick={(e) => ULopenTabs(e, 'ULVtab2')}>{t('account.application-setting')}</button>
                {/*<button className="ULVtablinks" style={{padding: "25px 10px"}} onClick={(e) => ULopenTabs(e, 'ULVtab3')}>{t('account.appearance')}</button> */}
            </div>
            <div id="ULVtab1" className="ULtabcontent-vertical ULbgF" style={{minHeight:"80vh"}}>            
                <Formik
                initialValues={{
                    name: fields.name,
                    email: fields.email,
                    //accountStatus:''
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string()
                        .required('Name is required'),
                    email: Yup.string()
                        .email('Email is invalid')
                        .required('Email is required'),
                    //accountStatus: Yup.string().required("Status is required"),
                })}
                onSubmit={fields => {
                    //console.log(fields);
                    //alert('SUCCESS!! :-)\n\n' + JSON.stringify(fields, null, 4));
                    showToast('wait!');
                    setTimeout(()=>{
                        updateFields({
                            name: fields.name,
                            email: fields.email
                        });
                        toggleAccountDetail(false);
                        showToastSuccess('Account Detail Saved Successfully');
                    }, 2000);
                    
                    /* setMessage({
                        data: "Registration is in progress...",
                        type: "alert-warning",
                      });

                    fetch(`${SERVER_URL}/user/register`, {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify(fields),
                    })
                    .then((res) => res.json())
                    .then((data) => {
                        const hasError = "error" in data && data.error != null;
                        setMessage({
                        data: hasError ? data.error : "Registered successfully",
                        type: hasError ? "alert-danger" : "alert-success",
                        });
                
                        !hasError && e.target.reset();
                    }); */
                }}
                render={({ errors, status, touched}) => (         //, setFieldValue             
                        <Form id="account_detail" className="UL-pd-t-25" >                            
                            <div style={{display:"inline-block", fontSize:"18px", fontWeight: 600, marginBottom: "45px" }}>{t('account.account-detail')}</div>
                            <div className="f-right ULd-flex">
                                {/* <button type="button" className={`ULbtn btn-ul-red UL-outlinedSizeSmall cr-pointer ${!activeAccountDetail ? "UL-d-block" : "UL-d-none"}`} onClick={()=> toggleAccountDetail(true)}>{t('account.edit-account')}</button> */}
                                <button type="submit" className={`ULbtn btn-ul-red UL-outlinedSizeSmall cr-pointer ${activeAccountDetail ? "UL-d-block" : "UL-d-none"}`} >{t('forms.save-button')}</button>
                                <button type="button" className={`ULbtn line-gray UL-outlinedSizeSmall cr-pointer UL-mg-l-15 ${activeAccountDetail ? "UL-d-block" : "UL-d-none"}`} onClick={()=> toggleAccountDetail(false)}>{t('forms.cancel-button')}</button>
                            </div>
                            {
                                !activeAccountDetail ?  <Aux>
                                                            <div className="form-group mb-3 ULd-flex">
                                                                <label htmlFor="name" className="label-name dIB UL-wd-25p fw-sb">{t('forms.name')}</label>
                                                                <span className="UL-mg-l-10 UL-mg-r-10" style={{width: "5%"}}>:</span>
                                                                <label className={`label-name dIB UL-wd-25p`}>{fields.name}</label>
                                                            </div>
                                                            <div className="form-group  mb-3 ULd-flex">
                                                                <label htmlFor="email" className="label-name dIB UL-wd-25p fw-sb">{t('forms.email')}</label>
                                                                <span className="UL-mg-l-10 UL-mg-r-10" style={{width: "5%"}}>:</span>
                                                                <label className={`label-name dIB UL-wd-25p`}>{fields.email}</label>
                                                            </div>
                                                            {/* <div className="form-group  mb-3 ULd-flex">
                                                                <label htmlFor="accountStatus" className="label-name dIB UL-wd-25p fw-sb">{t('account.status')}</label>
                                                                <span className="UL-mg-l-10 UL-mg-r-10" style={{width: "5%"}}>:</span>
                                                                <label className={`label-name dIB UL-wd-25p`}>{selectedOption.label}</label>
                                                            </div> */}
                                                        </Aux>
                                                        :
                                                        <Aux>
                                                            <div className="form-group mb-3 ULd-flex">
                                                                <label htmlFor="name" className="label-name dIB UL-wd-25p fw-sb">{t('forms.name')}</label>
                                                                <span className="UL-mg-l-10 UL-mg-r-10" style={{width: "5%"}}>:</span>
                                                                <Field name="name" placeholder="Enter name" type="text" className={'UL-form-control UL-wd-40p' + (errors.name && touched.name ? ' is-invalid' : '') + (activeAccountDetail ? " UL-d-block" : " UL-d-none")} />
                                                                <ErrorMessage name="name" component="div" className={`ulError-color UL-wd-25p UL-mg-l-10 UL-mg-t-10 ${activeAccountDetail ? " UL-d-block" : " UL-d-none"}`} />
                                                            </div>
                                                            <div className="form-group  mb-3 ULd-flex">
                                                                <label htmlFor="email" className="label-name dIB UL-wd-25p fw-sb">{t('forms.email')}</label>
                                                                <span className="UL-mg-l-10 UL-mg-r-10" style={{width: "5%"}}>:</span>
                                                                <Field name="email" placeholder="Enter your Email Id" type="text" className={'UL-form-control UL-wd-40p' + (errors.email && touched.email ? ' is-invalid' : '') + (activeAccountDetail ? " UL-d-block" : " UL-d-none")} />
                                                                <ErrorMessage name="email" component="div" className={`ulError-color UL-wd-25p UL-mg-l-10 UL-mg-t-10 ${activeAccountDetail ? " UL-d-block" : " UL-d-none"}`} />
                                                            </div>
                                                            <div className="form-group  mb-3 ULd-flex">
                                                                <label htmlFor="accountStatus" className="label-name dIB UL-wd-25p fw-sb">{t('account.status')}</label>
                                                                <span className="UL-mg-l-10 UL-mg-r-10" style={{width: "5%"}}>:</span>
                                                                <label className={`label-name dIB UL-wd-25p`}>{selectedOption.label}</label>
                                                                {/* <Select
                                                                    className="UL-wd-40p"
                                                                    name="accountStatus"
                                                                    value={selectedOption}
                                                                    id="accountStatus"
                                                                    defaultValue={selectedOption}
                                                                    onChange={
                                                                        (opt, e) => {
                                                                            setSelectedOption(opt);
                                                                            setFieldValue("accountStatus", opt.value)
                                                                        }
                                                                    }
                                                                    options={accountStatusOptions}
                                                                    isMulti={false}
                                                                    isSearchable={false}
                                                                /> */}
                                                            </div>
                                                        </Aux>
                            }
                            
                            {/* <hr className="Ulsolid-border UL-mg-t-40 UL-mg-b-20"></hr>    */}                         
                        </Form>
                                        
                )}
            />
            {/* <Formik
                initialValues={{
                    oldPassword: '',
                    password: '',
                    confirmPassword: ''
                }}
                validationSchema={Yup.object().shape({
                    oldPassword: Yup.string()
                        .min(6, 'Old Password must be at least 6 characters')
                        .required('Old Password is required'),
                    password: Yup.string()
                        .min(6, 'Password must be at least 6 characters')
                        .required('Password is required'),
                    confirmPassword: Yup.string()
                        .oneOf([Yup.ref('password'), null], 'Passwords must match')
                        .required('Confirm Password is required')
                })}
                onSubmit={fields => {
                    alert('SUCCESS!! :-)\n\n' + JSON.stringify(fields, null, 4));
                    
                    showToast('wait!');
                    setTimeout(()=>{
                        toggleChangePassword(false);
                        showToastError('Error in change password');
                    }, 2000);
                    /* setMessage({
                        data: "Registration is in progress...",
                        type: "alert-warning",
                      });

                    fetch(`${SERVER_URL}/user/register`, {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify(fields),
                    })
                    .then((res) => res.json())
                    .then((data) => {
                        const hasError = "error" in data && data.error != null;
                        setMessage({
                        data: hasError ? data.error : "Registered successfully",
                        type: hasError ? "alert-danger" : "alert-success",
                        });
                
                        !hasError && e.target.reset();
                    }); 
                }}
                render={({ errors, status, touched}) => (
                    <Aux>                       
                        <Form id="change_password" className="UL-pos-relative UL-d-inline-block w100" style={{minHeight:"137px"}}>                            
                            <div style={{display:"inline-block", fontSize:"18px", fontWeight: 600, marginBottom: "30px"}}>{t('account.change-password')}</div>
                            {
                                !activeChangePassword ? <div className="form-group  mb-3 ULd-flex">
                                                            <label htmlFor="oldPassword" className="label-name dIB UL-wd-25p fw-sb">{t('forms.password')}</label>
                                                            <span className="UL-mg-l-10 UL-mg-r-10" style={{width: "5%"}}>:</span>
                                                            <span className={'UL-wd-25p'} >******</span>
                                                            <span className="UL-wd-40p UL-mg-l-10 cr-pointer UL-fontsize-12 tx-indigo" onClick={()=> toggleChangePassword(true)}>{t('account.change-password')}</span>
                                                        </div>
                                                       :
                                                       <Aux>
                                                            <div className="f-right">
                                                                <button type="submit" className="ULbtn btn-ul-red UL-outlinedSizeSmall cr-pointer">{t('forms.save-button')}</button>
                                                                <button type="submit" className="ULbtn line-gray UL-outlinedSizeSmall cr-pointer UL-mg-l-15" onClick={()=> toggleChangePassword(false)}>{t('forms.cancel-button')}</button>
                                                            </div>
                                                            <div className="form-group  mb-3 ULd-flex">
                                                                <label htmlFor="oldPassword" className="label-name dIB UL-wd-25p fw-sb">{t('account.old-password')}</label>
                                                                <span className="UL-mg-l-10 UL-mg-r-10" style={{width: "5%"}}>:</span>
                                                                <Field name="oldPassword" placeholder="Enter old password" type="password" className={'UL-form-control UL-wd-40p' + (errors.oldPassword && touched.oldPassword ? ' is-invalid' : '')} />
                                                                <ErrorMessage name="oldPassword" component="div" className="ulError-color UL-wd-25p UL-mg-l-10 UL-mg-t-10" />
                                                            </div>                            
                                                            <div className="form-group  mb-3 ULd-flex">
                                                                <label htmlFor="password" className="label-name dIB UL-wd-25p fw-sb">{t('account.new-password')}</label>
                                                                <span className="UL-mg-l-10 UL-mg-r-10" style={{width: "5%"}}>:</span>
                                                                <Field name="password" placeholder="Enter password" type="password" className={'UL-form-control UL-wd-40p' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                                                <ErrorMessage name="password" component="div" className="ulError-color UL-wd-25p UL-mg-l-10 UL-mg-t-10" />
                                                            </div>
                                                            <div className="form-group  mb-3 ULd-flex">
                                                                <label htmlFor="confirmPassword" className="label-name dIB UL-wd-25p fw-sb">{t('account.confirm-new-password')}</label>
                                                                <span className="UL-mg-l-10 UL-mg-r-10" style={{width: "5%"}}>:</span>
                                                                <Field name="confirmPassword" placeholder="Enter confirm Password" type="password" className={'UL-form-control UL-wd-40p' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')} />
                                                                <ErrorMessage name="confirmPassword" component="div" className="ulError-color UL-wd-25p UL-mg-l-10 UL-mg-t-10" />
                                                            </div>
                                                        </Aux> 
                            }
                            
                            
                                                        
                            <hr className="Ulsolid-border UL-mg-t-35 UL-mg-b-10 w100" style={{bottom:"0"}}></hr>                            
                        </Form>
                            <div className="UL-mg-t-15">
                                {/* <hr className="Ulsolid-border UL-mg-t-40 UL-mg-b-10"></hr>
                                    <h5 style={{display:"inline-block"}}>Language Preference</h5>
                                    <div className="UL-r-20 UL-b-20">
                                        <LanguageSelector optionLable={true} selectedLabel={true}/>
                                    </div> *}
                                <div style={{display:"inline-block", fontSize:"18px", fontWeight: 600, marginBottom: "15px"}}>{t('account.delete-account')}</div>
                            
                                <p>{t('account.delete-account-desc')}</p>
                                <div>
                                <span onClick={() => document.getElementById('ulsimple').style.display='block'} className="cr-pointer UL-fontsize-12 tx-indigo">{t('account.delete-my-account')}</span>
                                </div>                                
                                <div id="ulsimple" className="modal">
                                    <div className="popup" style={{width:"50%"}}>
                                <h4 style={{textAlign:"center"}}>{t('account.delete-account')}</h4>
                                        {/* <a onClick={() => document.getElementById('ulsimple').style.display='none'} className="close" href="#">&times;</a> *}
                                        <div className="content" style={{textAlign:"center", color:"#939598", fontSize:"15px"}}>
                                            {t('account.delete-account-modal-desc')}
                                        </div>
                                        <span className="ULbtn btn-ul-red cr-pointer" style={{display:"block", width:"60%", margin:"40px 20% 10px"}}>{t('account.delete-account')}</span>
                                        
                                        <span onClick={() => document.getElementById('ulsimple').style.display='none'} className="cr-pointer cl000" style={{display:"block", width:"100%", textAlign:"center"}}>{t('forms.cancel-button')}</span>
                                        
                                        
                                    </div>
                                </div>
                            </div>
                    </Aux>                    
                )}
            /> */}
            </div>
            <div id="ULVtab2" className="ULtabcontent-vertical ULbgF" style={{minHeight:"80vh"}}>
                <div className="UL-pd-t-25">
                    <div style={{display:"inline-block", fontSize:"18px", fontWeight: 600, marginBottom: "45px" }}>{t('account.general-setting')}</div>
                    <div className="form-group mb-3 ULd-flex">
                        <label htmlFor="name" className="label-name dIB UL-wd-25p fw-sb">{t('forms.averageTo30Degree')}</label>
                        <span className="UL-mg-l-10 UL-mg-r-10" style={{width: "5%"}}>:</span>
                        <label className="ULswitchS UL-pos-relative UL-mg-r-30 theme-button" style={{left: "0", top: "3px"}}>
                                <input type="checkbox" onClick={() => set30Degree(!averageTo30Degree)} checked={averageTo30Degree}/>
                                <span className="ULsliderS ULround UL-pos-absolute cr-pointer" style={{border: "1px solid #C02032"}}></span>
                        </label>
                    </div>
                </div>
            </div>
            {/* <div id="ULVtab3" className="ULtabcontent-vertical ULbgF" style={{minHeight:"100vh"}}>
                <h5>Heading 3</h5>
                <p>Heading 3 content</p>
            </div> */}
            </div>
            
        </div>
        
    )
}

export default MyAccount;