import React, { Suspense } from "react";
import "./App.css";
import Routes from "./util/Routes";
import { AuthContextProvider } from "./context/AuthContext";
import { ToastContextProvider } from "./context/ToastContext";
import { ThemeContextProvider } from "./context/ThemeContext";
import { MonthDetailContextProvider } from "./context/MonthDetailContext";

const App = (props) => {
  return (
    <Suspense fallback="loading">
      <ThemeContextProvider>
        <ToastContextProvider>
          <AuthContextProvider>
            <MonthDetailContextProvider>
              <Routes />
            </MonthDetailContextProvider>
          </AuthContextProvider>
        </ToastContextProvider>
      </ThemeContextProvider>
    </Suspense>
  );
};
export default App;
