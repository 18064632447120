import React from 'react';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const LoadingRing = (props) => {
    return (
        <Loader
        type="ThreeDots"
        color="#c02032"
        height={100}
        width={100}
        timeout={30000} //3 secs
        visible={props.visible}
      />
    )
}

export default LoadingRing;