import { useTranslation } from "react-i18next";
import { PROCESS_FILE, TIME_SERIES_FILE } from "../../util/Api";
import {
  STOP_INTERVAL,
  REFRESH_INTERVAL,
  ITEMS_COUNT_PER_PAGE,
  PAGE_RANGE_DISPLAYED,
} from "../../util/Constants";
import React, { useState, useEffect } from "react";
import useAuthContext from "../../hooks/useAuthContext";
import axios from "axios";
import "moment-timezone";
import Pagination from "react-js-pagination";
import Loader from "../../components/UI/Loader/Loader";
import Aux from "../../hoc/Auxiliary/Auxiliary";
import "./Files.css";
import Moment from "react-moment";
import { useInterval } from "../../hooks/useInterval";

const FilesViewer = () => {
  const { t } = useTranslation("common");
  const [filesDataFrom, setFilesDataFromDB] = useState([]);
  const { authTokens } = useAuthContext();
  const [activePage, setActivePage] = useState(1);
  const [totalRowsCount, setTotalRowsCount] = useState(PAGE_RANGE_DISPLAYED);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(ITEMS_COUNT_PER_PAGE);
  const [isLoadingState, setLoadingState] = useState(false);
  const [seconds, setSeconds] = useState(REFRESH_INTERVAL);
  useEffect(() => {
      const clickHandler = ({ target }) => {
        const container = document.getElementById(`action_list`);
        if (target.id || (container && container.contains(target))) {
            return;
        }
        updateKey(target.id, false);
      };

      document.addEventListener("click", clickHandler);
      // these functions clean up the event listeners
      return () => document.removeEventListener("click", clickHandler);
  });
  useEffect(() => {
    getUploadedFilesDetails();
  }, []);

  const getStartDate = (applicablePeriod) => {
    let period = applicablePeriod.split('/');
    return [
      period[0],
      period[1]
    ]
  }
  const getUploadedFilesDetails = async() => {
    setLoadingState(true);
    setFilesDataFromDB([]);
    await axios
      .get(TIME_SERIES_FILE, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${authTokens}`,
        },
      })
      .then(function (response) {
        // handle success
        let list = [];
        setTotalRowsCount(response.data.length);
        response.data.map((obj) => {
          let newObj = {};
          newObj["id"] = obj.id;
          newObj["name"] = obj.name;
          newObj["status"] = obj.status;
          newObj["type"] = obj.type;
          newObj["uploaded_at"] = obj.uploaded_at;
          newObj["error_string"] = obj.error_string === null ? '---' : obj.error_string;
          newObj["applicablePeriod"] = null;
          newObj["isShow"] = false;
          newObj["isDownloading"] = false;
          newObj["isDeleting"] = false;
          if(obj.applicablePeriod) {
            const dates = getStartDate(obj.applicablePeriod);
            newObj["start_date"] = dates[0];
            newObj["end_date"] = dates[1];
            newObj["applicablePeriod"] = 'applicablePeriod';
          }
          list.push(newObj);
        });
        setFilesDataFromDB(list);
        setLoadingState(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setLoadingState(false);
      });
  };
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    setStartIndex((pageNumber - 1) * ITEMS_COUNT_PER_PAGE);
    setEndIndex(pageNumber * ITEMS_COUNT_PER_PAGE);
  };
  const reprocessFile = async (id) => {
    // console.log(id);
    setSeconds(STOP_INTERVAL);
    await axios
      .get(`${PROCESS_FILE}?id=${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${authTokens}`,
        },
      })
      .then(function (response) {
        // handle success
        getUploadedFilesDetails();
        setSeconds(REFRESH_INTERVAL);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };
  const softDelete = async (id) => {
    // console.log(id);
    updateKey(id, true, 'isDeteing');
    setSeconds(STOP_INTERVAL);
    await axios
      .get(`${TIME_SERIES_FILE}/${id}/softDelete`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${authTokens}`,
        },
      })
      .then(function (response) {
        // handle success
        updateKey(id, false, 'isDeteing');
        getUploadedFilesDetails();
        setSeconds(REFRESH_INTERVAL);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };
  const updateKey = (id, status, key = 'isShow') => {
    if(key === 'isShow') {
      setSeconds(STOP_INTERVAL);
    }
    let list = [];
    filesDataFrom.map((obj) => {
      obj[key] = false;
      if(obj.id === id) {
        obj[key] = status;
      }
      list.push(obj);
    });
    setFilesDataFromDB(list);
  };
  const downloadFile = async (id, name) => {  
    updateKey(id, true, 'isDownloading');
    setSeconds(STOP_INTERVAL);
    await axios
      .get(`${TIME_SERIES_FILE}/${id}/rawdata`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${authTokens}`,
        },
      })
      .then(function (response) {
        // handle success
        updateKey(id, false, 'isDownloading');
        let hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(response.data);
        hiddenElement.target = '_blank';
        hiddenElement.download = name;
        hiddenElement.click();
        setSeconds(REFRESH_INTERVAL);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };
  useInterval(getUploadedFilesDetails, seconds);
  return (
      <Aux>
    <div className="UL-outer-setting">
          <h3>{t("file-app.page-heading")}</h3>

      {/* TABLE OF EXISTING wfpcS */}
      <h5 className="UL-mg-l-10">{t("file-app.table-heading")}</h5>
      <div
        className="UL-card-width-extralarge UL-pos-relative"
        style={{ marginLeft: "5px", maxWidth: "98.9%" }}
      >
        <div className="medium-card ul-cardHight  UL-card-height" style={{ maxHeight: "100rem" }}>
          <div className="card-body">
            <div className="ULtabs-section ul-minHeight">
              <div className="ULtab content1 tabledataR">
                <div className="Table" role="table">
                  <div className="Heading" role="rowgroup">
                    <div className="Cell" role="row">
                      <p>{t("file-app.table-name")}</p>
                    </div>
                    <div className="Cell" role="row">
                      <p>{t("file-app.table-type")}</p>
                    </div>
                    <div className="Cell" role="row">
                      <p>{t("file-app.table-status")}</p>
                    </div>
                    <div className="Cell" role="row">
                      <p>{t("file-app.table-error")}</p>
                    </div>
                    <div className="Cell" role="row">
                      <p>{t("file-app.table-period")}</p>
                    </div>
                    <div className="Cell" role="row">
                      <p>{t("file-app.table-uploaded")}</p>
                    </div>
                    <div className="Cell UL-text-center" role="row">
                      <p>{t("file-app.table-actions")}</p>
                    </div>
                  </div>
                  {filesDataFrom.length ? (
                    filesDataFrom
                      .slice(startIndex, endIndex)
                      .map((data, index) => (
                        <div className="table-Row UL-pos-relative" role="rowgroup" key={index}>
                          <div to="evts-overview" className="Cell" role="row">
                            <p className="cr-pointer">
                                {data.name}
                            </p>
                          </div>
                          <div className="Cell" role="row">
                            <p>{data.type}</p>
                          </div>
                          <div className="Cell" role="row">
                            <p>
                                {
                                  data.status === 'processing'
                                  ? 
                                  <i className="fas fa-spinner fa-spin UL-mg-r-5"></i>
                                  :
                                  ''
                                }
                              {data.status}
                            </p>
                          </div>
                          <div className="Cell" role="row">
                            <p>
                              {data.error_string}
                            </p>
                          </div>
                          <div className="Cell"role="row">
                            <p>
                            {
                              data.applicablePeriod
                              ?
                              <Aux>
                                    <Moment format="YYYY-MM-DD HH:mm:ss">
                                      {data.start_date}
                                    </Moment>
                                    &nbsp;-&nbsp;
                                    <Moment format="YYYY-MM-DD HH:mm:ss">
                                      {data.end_date}
                                    </Moment>
                              </Aux>
                              :
                              '----'
                            }
                            </p>
                          </div>
                          <div className="Cell" role="row">
                            <p>
                              <Moment format="YYYY-MM-DD HH:mm:ss">
                                {data.uploaded_at}
                              </Moment>
                            </p>
                          </div>
                          <div className="Cell text-center" role="row" style={{borderBottom: "1px solid #e8e8e8"}}>
                            <span className="align-self UL-pd-l-30">
                              <label className="UL-height15px UL-mg-t-4 cr-pointer" id={data.id}
                                onClick={()=> updateKey(data.id, !data.isShow) }>
                                  <div className="setting-dot"> </div>
                              </label>
                              {
                                data.isShow
                                ? 
                                (
                                  <div
                                    id="action_list"
                                    className="mt5 collapse-text-icon pd10 UL-pos-absolute UL-r-60 z-index-10 UL-t-0  UL-text-left"
                                    style={{width: "126px"}}>                                    
                                      {
                                        data.isDownloading ? 
                                        (
                                          <div> 
                                            <i className="fas fa-spinner fa-spin"></i>
                                            Download 
                                          </div>
                                        )
                                        :
                                        (
                                          <div className="cr-pointer" onClick={()=>downloadFile(data.id, data.name)} > 
                                            <i className="fas fa-download UL-mg-r-5"></i>
                                            Download 
                                          </div>
                                        )
                                      }
                                      {
                                        data.status === 'processing' ? 
                                        (
                                          <div className="UL-pd-t-10">
                                            <i className="fas fa-spinner fa-spin UL-mg-r-5"></i> Reprocess
                                          </div>
                                        )
                                        :
                                        (
                                          <div className="UL-pd-t-10 cr-pointer" onClick={()=>reprocessFile(data.id)}> 
                                            <i className="fas fa-sync-alt UL-mg-r-5"></i> Reprocess 
                                          </div>
                                        )
                                      }
                                    {
                                      data.status === 'error' ? 
                                      (
                                          data.isDeleting ?
                                          (
                                            <div className="UL-pd-t-10"> 
                                              <i className="fas fa-spinner fa-spin UL-mg-r-5"></i> Delete 
                                            </div>
                                          )
                                          :
                                          (
                                            <div className="UL-pd-t-10 cr-pointer" onClick={()=>softDelete(data.id)} > 
                                              <i className="far fa-trash-alt UL-mg-r-5"></i> Delete 
                                            </div>
                                          )                                        
                                      ) : ''
                                    }
                                  </div>
                                ) : ''
                              }
                            </span>
                          </div>
                        </div>
                      ))
                  ) : isLoadingState ? (
                    <div
                      style={{
                        position: "absolute",
                        top: "40%",
                        left: "45%",
                        width: "100%",
                      }}
                    >
                      <Loader visible={isLoadingState} />
                    </div>
                  ) : (
                    <div
                      style={{
                        position: "absolute",
                        top: "40%",
                        left: "32%",
                        width: "100%",
                      }}
                    >
                      <div>
                        {t("toast-app.no-data")}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div id="myDIV2" className="f-right">
              {filesDataFrom.length ? (
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={ITEMS_COUNT_PER_PAGE}
                  totalItemsCount={totalRowsCount}
                  pageRangeDisplayed={PAGE_RANGE_DISPLAYED}
                  onChange={handlePageChange.bind(this)}
                  innerClass="UL-r-0 text-right ULcltextfff UL-mg-t-35 pagination-position"
                  activeClass="active2"
                  itemClass="pagn2 f-left UL-mg-r-5"
                  linkClass="cl000"
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
    </Aux>
  );
};
export default FilesViewer;
