import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import "./Login.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from 'yup';
import Aux from '../../../hoc/Auxiliary/Auxiliary';
import {useTranslation} from "react-i18next";
import useToastContext from "../../../hooks/useToastContext";
import useAuthContext from "../../../hooks/useAuthContext";
import { LOGIN_URL } from "../../../util/Api";
const Login = () => {
    const {t} = useTranslation('common');
    const [isLoggedIn, setLoggedIn] = useState(false);
    
    const { authTokens, setAuthTokens } = useAuthContext();
    const { showToast, hideToast, showToastSuccess, showToastError } = useToastContext();
    const rememberMe = localStorage.getItem("rememberMe");
    let rememberEmail = '';
    let rememberPassword = '';
    if (rememberMe) {
        // Create a buffer from the string 
        let bufferObj = Buffer.from(rememberMe, "base64"); 
        
        // Encode the Buffer as a utf8 string 
        let decodedString = bufferObj.toString("utf8"); 
        let cred = decodedString.split(':');
        rememberEmail = cred[0];
        rememberPassword = cred[1];
    }
    const [isChecked, setRememberMe] = useState(rememberEmail ? true : false);
    const onChangeCheckbox = event => {  
            setRememberMe(event.target.checked);
    }
    
    if (isLoggedIn || authTokens) {
        return <Redirect to="/dashboard" />;
    }

    return (
        
            <Formik
            initialValues={{
                email: rememberEmail ? rememberEmail : '',
                password: rememberPassword ? rememberPassword : ''
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string()
                    .email('Email is invalid')
                    .required('Email is required'),
                password: Yup.string()
                    .min(6, 'Password must be at least 6 characters')
                    .required('Password is required'),
            })}
            onSubmit={fields => {
                showToast(`${t("toast-app.wait")}`);                
                const encodedString = Buffer.from(`${fields.email}:${fields.password}`).toString('base64');
                fetch( LOGIN_URL , {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                          "Authorization": `Basic ${encodedString}`
                        },
                        body: JSON.stringify(fields),
                    })
                    .then((res) => res.json())
                    .then((result) => {
                        hideToast();
                        if (result.success) {
                            showToastSuccess(result.message);
                            setAuthTokens({
                                encodedString : encodedString,
                                isAdmin : result.is_admin,
                                name: result.name,
                                email: result.email,
                                rememberMe: isChecked
                            });
                            setLoggedIn(true);
                        } else {
                            showToastError(`${t("toast-app.incorrect-cred")}`);
                        }
                    });
            }}
            render={({ errors, status, touched }) => (
                <Aux>
                    <div className="UL-login-text ulError-color">{t('forms.log-in')}</div>
                    <Form className="UL-padding43rem">
                        
                        <div className="form-group UL-mb-6">
                            <label htmlFor="email" className="label-name dIB">{t('forms.email')}</label>
                            <Field name="email" type="text" placeholder={t('forms.email-placeholder')} className={'UL-form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                            <ErrorMessage name="email" component="div" className="ulError-color" />
                        </div>
                        <div className="form-group UL-mb05" style={{position: "relative"}}>
                            <label htmlFor="password" className="label-name dIB">{t('forms.password')}</label>
                            <Field name="password" placeholder={t('forms.password-placeholder')} type="password" className={'UL-form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                            <ErrorMessage name="password" component="div" className="ulError-color" />
                        </div>
                        
                        <div className="UL-mb-9">
                            <label className="ULform-check-label">
                                <Field className="ULform-check-input" name="remember_me" type="checkbox" checked={isChecked} onChange={(event)=>onChangeCheckbox(event)} />
                                <span className="ULform-check-sign">{t('forms.remember-me')}</span>
                            </label>
                        </div>
                        <button type="submit" className="ULbtn btn-ul-red w100 cr-pointer">{t('forms.log-in')}</button>
                    </Form>
                </Aux>
               
            )}
        />
    );    
}
export default Login;
