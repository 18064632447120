import React from "react";
import NavigationItem from "../NavigationItem/NavigationItem";
import { useTranslation } from "react-i18next";
import useAuthContext from "../../../hooks/useAuthContext";

const MenuDropdown = (props) => {
  const { t } = useTranslation("common");
  const { name, email } = useAuthContext();
  const nameInitials = name.match(/\b(\w)/g).join('');

  return (
    <div
      id={`menu-${props.id}`}
      className={` UL-pos-relative ${props.open ? "UL-d-block" : "UL-d-none"}`}
    >
      <div className="UL-profile-div ULbgF ULBorderForm UL-rounded f-right UL-pos-absolute UL-mg-t-40 UL-r-0" style={{minHeight:'275px'}}>
        <div className="ul-col-md-12">
          <div className="UL-profile Ul-float-revert">
            <div className="UL-profile-pic">
              <p>{nameInitials}</p>
            </div>
            {/* <div className="upload-pic text-center">
              <i className="fas fa-camera"></i>
            </div> */}
          </div>
        </div>
        <div className="ul-col-md-12 UL-mg-t-40">
          <div className="UL-border-bt UL-clearBoth">
            <h2>{name}</h2>
            <div className="ul-email">{email}</div>
            {/* <div className="UL-service-dpt">Information Service</div>
            <div className="UL-service-dpt">TS Solution Architect</div> */}
          </div>
        </div>
        <div className="ul-col-md-12">
          <div className="UL-profile-menu pd10">
            <ul>
              <NavigationItem link="/my-account" id="account" display>
                {t("menu.account-details")}
              </NavigationItem>
              <NavigationItem link="/logout" id="logout" display>
                {" "}
                {t("menu.logout")}
              </NavigationItem>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuDropdown;
