import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import DropZone from "../../DropZone/DropZone";
import { TIME_SERIES_FILE, REPORT, STABILITY } from "../../../util/Api";
import { thousandsSeparators } from "../../../util/CommonFunctions";
import {
  STABLE_INTERVAL,
  UNSTABLE_INTERVAL,
  ITEMS_COUNT_PER_PAGE,
  PAGE_RANGE_DISPLAYED,
  PREFERRED_TIMEZONE
} from "../../../util/Constants";
import useAuthContext from "../../../hooks/useAuthContext";
import useMonthDetailContext from "../../../hooks/useMonthDetailContext";
import { useInterval } from "../../../hooks/useInterval";
import axios from "axios";
import Moment from "react-moment";
import "moment-timezone";
import Pagination from "react-js-pagination";
import "./index.css";
import MonthlyReport from "../report/MonthlyReport";
import Loader from "../../UI/Loader/Loader";

let reportOverViewDataFromDB = [];

const DashboardApp = () => {
  const { t } = useTranslation("common");
  const [reportOverViewData, setReportOverviewData] = useState(
    reportOverViewDataFromDB
  );
  const { authTokens, isAdmin, name, email } = useAuthContext();
  const { monthDetailData, setMonthDetailData } = useMonthDetailContext();
  const [stabilityFlag, setStabilityFlag] = useState(false);
  const [seconds, setSeconds] = useState(UNSTABLE_INTERVAL);
  const [activePage, setActivePage] = useState(1);
  const [totalRowsCount, setTotalRowsCount] = useState(5);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(ITEMS_COUNT_PER_PAGE);
  const [isLoadingState, setLoadingState] = useState(false);

  useEffect(() => {
    getUpdatedReportData();
  }, []);

  const systemStabilityCheck = () => {
    axios
      .get(STABILITY, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${authTokens}`,
        },
      })
      .then(function (response) {
        // handle success
        setStabilityFlag(response.data.stable);
        if (response.data.stable) {
          setSeconds(STABLE_INTERVAL);
        } else {
          setSeconds(UNSTABLE_INTERVAL);
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };
  useInterval(systemStabilityCheck, seconds);
  useEffect(() => {
    if (stabilityFlag) {
      getUpdatedReportData();
    }
  }, [stabilityFlag]);

  const getUpdatedReportData = () => {
    setLoadingState(true);
    axios
      .get(REPORT, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${authTokens}`,
        },
      })
      .then(function (response) {
        // handle success
        let updatedReportData = [];
        setTotalRowsCount(response.data.length);
        response.data.map((obj) => {
          let newObj = {};
          let applicablePeriod = obj.applicablePeriod.split("/");
          newObj["month_name"] = obj.applicableMonth;
          newObj["start_date"] = applicablePeriod[0];
          newObj["end_date"] = applicablePeriod[1];
          newObj["actual_energy"] = thousandsSeparators(obj.actualEnergyKWh.toFixed(2));
          newObj["deemed_energy"] = thousandsSeparators(obj.deemedEnergyKWh.toFixed(2));
          newObj["undelivered_energy"] = thousandsSeparators(obj.undeliveredEnergyKWh.toFixed(2));
          newObj["no_of_events"] = obj.events.filter((event) => {
              return event.status !== "uncurtailed"
          });
          newObj["total_oprtr_shut"] = thousandsSeparators((obj.operatorCurtailment.plannedShutdown).toFixed(2));
          newObj["total_oprtr_curt"] = thousandsSeparators((obj.operatorCurtailment.plannedCurtailment).toFixed(2));
          newObj["total_owner_shut"] = thousandsSeparators((obj.ownerCurtailment.plannedShutdown).toFixed(2));
          newObj["total_owner_curt"] = thousandsSeparators((obj.ownerCurtailment.plannedCurtailment).toFixed(2));
          newObj["total_unpln_shut"] = thousandsSeparators((obj.unplannedShutdown).toFixed(2));
          updatedReportData.push(newObj);
        });
        console.log(JSON.stringify(updatedReportData));
        setReportOverviewData(updatedReportData);
        setLoadingState(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setLoadingState(false);
      });
  };

  const fileUploadSuccessHandler = (response) => {
    if (response) {
      setSeconds(UNSTABLE_INTERVAL);
      setStabilityFlag(false);
      setReportOverviewData(reportOverViewData);
    }
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    setStartIndex((pageNumber - 1) * ITEMS_COUNT_PER_PAGE);
    setEndIndex(pageNumber * ITEMS_COUNT_PER_PAGE);
  };

  return (
    <div style={{ width: "100%", marginLeft: "20px", marginBottom: "10px" }}>
      <h3>{t("dashboard-app.page-heading")}</h3>
      {/* METADATA FORM, might not be needed */}
      <h5>{t(isAdmin ? "dashboard-app.dropzone-heading" : "")}</h5>
      {/* DROPZONE */}
      {isAdmin ? (
        <DropZone
          uploadUrl={TIME_SERIES_FILE}
          fileUploadSuccess={(response) => fileUploadSuccessHandler(response)}
        />
      ) : (
        ""
      )}
      {/* TABLE OF EXISITING DATASETS */}
      <h5>{t("dashboard-app.uploadedData-heading")}</h5>
      <div
        className="UL-card-width-extralarge UL-pos-relative"
        style={{ marginLeft: "0" }}
      >
        <div className="medium-card ul-cardHight datatable UL-card-height">
          <div className="card-body">
            <div className="ULtabs-section ul-minHeight">
              <div className="ULtab content1 monthtable">
                <div className="Table">
                  <div className="Heading" role="rowgroup">
                    <div className="Cell datatableHint" role="row">
                      <p>{t("dashboard-app.table-name")}</p>
                    </div>
                    <div className="Cell" role="row">
                      <p>{t("dashboard-app.table-startDate")}</p>
                    </div>
                    <div className="Cell" role="row">
                      <p>{t("dashboard-app.table-endDate")}</p>
                    </div>
                    <div className="Cell" role="row">
                      <p>{t("dashboard-app.table-actual")}</p>
                    </div>
                    <div className="Cell" role="row">
                      <p>{t("dashboard-app.table-deemed")}</p>
                    </div>
                    <div className="Cell" role="row">
                      <p>{t("dashboard-app.table-undelivered")}</p>
                    </div>
                    <div className="Cell" role="row">
                      <p>{t("dashboard-app.table-evts")}</p>
                    </div>
                    <div className="Cell" role="row">
                      <p>{t("dashboard-app.table-actions")}</p>
                    </div>
                  </div>
                  {reportOverViewData.length ? (
                    reportOverViewData
                      .slice(startIndex, endIndex)
                      .map((data, index) => (
                        <div key={index} className="table-Row" role="rowgroup">
                          <div className="Cell" role="row">
                            <Link
                              to="dataset"
                              onClick={() => setMonthDetailData(data)}
                            >
                            <p>
                              <Moment format="MMMM">{data.month_name}</Moment>
                            </p>
                            </Link>
                          </div>
                          <div className="Cell" role="row">
                            <p>
                              <Moment format="DD/MM/YY" tz={PREFERRED_TIMEZONE}>
                                {data.start_date}
                              </Moment>
                            </p>
                          </div>
                          <div className="Cell" role="row">
                            <p>
                              <Moment format="DD/MM/YY" tz={PREFERRED_TIMEZONE}>
                                {data.end_date}
                              </Moment>
                            </p>
                          </div>
                          <div className="Cell" role="row">
                            <p>{data.actual_energy}</p>
                          </div>
                          <div className="Cell" role="row">
                            <p>{data.deemed_energy}</p>
                          </div>
                          <div className="Cell" role="row">
                            <p>{data.undelivered_energy}</p>
                          </div>
                          <div className="Cell" role="row">
                            <p>{data.no_of_events.length}</p>
                          </div>
                          <div className="Cell" role="row">
                            <p
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                MonthlyReport({
                                  overviewData: {
                                    deemed_energy: data.deemed_energy,
                                    actual_energy: data.actual_energy,
                                    undelivered_energy: data.undelivered_energy,
                                  },
                                  eventDetailData: data.no_of_events,
                                  startDate: data.start_date,
                                  name:name,
                                  email:email,
                                  total_oprtr_shut : data.total_oprtr_shut,
                                  total_oprtr_curt :  data.total_oprtr_curt,
                                  total_owner_shut :  data.total_owner_shut,
                                  total_owner_curt :  data.total_owner_curt,
                                  total_unpln_shut : data.total_unpln_shut
                                })
                              }
                            >
                              <strong>{t("dashboard-app.get-report")}</strong>
                            </p>
                          </div>
                        </div>
                      ))
                  ) : isLoadingState ? (
                    <div
                      style={{
                        position: "absolute",
                        top: "40%",
                        left: "45%",
                        width: "100%",
                      }}
                    >
                      <Loader visible={isLoadingState} />
                    </div>
                  ) : (
                    <div
                      style={{
                        position: "absolute",
                        top: "40%",
                        left: "32%",
                        width: "100%",
                      }}

                      className="no-data"
                    >
                      <div>
                        {t("toast-app.no-data")}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div id="myDIV2" className="f-right">
              {reportOverViewData.length ? (
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={ITEMS_COUNT_PER_PAGE}
                  totalItemsCount={totalRowsCount}
                  pageRangeDisplayed={PAGE_RANGE_DISPLAYED}
                  onChange={handlePageChange.bind(this)}
                  innerClass="UL-r-0 text-right ULcltextfff UL-mg-t-35 pagination-position"
                  activeClass="active2"
                  itemClass="pagn2 f-left UL-mg-r-5"
                  linkClass="cl000"
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DashboardApp;
