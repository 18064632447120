import { useTranslation } from "react-i18next";
import { WIND_FARM_POWER_CURVE } from "../../../util/Api";
import {
  ITEMS_COUNT_PER_PAGE,
  PAGE_RANGE_DISPLAYED,
  PREFERRED_TIMEZONE
} from "../../../util/Constants";
import { CSVLink } from "react-csv";
import React, { useState, useEffect } from "react";
import useAuthContext from "../../../hooks/useAuthContext";
import useMonthDetailContext from "../../../hooks/useMonthDetailContext";
import axios from "axios";
import Moment from "react-moment";
import "moment-timezone";
import Pagination from "react-js-pagination";
import Loader from "../../UI/Loader/Loader";

const WFPCViewer = () => {
  const { t } = useTranslation("common");
  const [wfpcDataFrom, setWFPCDataFromDB] = useState([]);
  const { authTokens } = useAuthContext();
  const { averageTo30Degree } = useMonthDetailContext();
  const [activePage, setActivePage] = useState(1);
  const [totalRowsCount, setTotalRowsCount] = useState(PAGE_RANGE_DISPLAYED);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(ITEMS_COUNT_PER_PAGE);
  const [isLoadingState, setLoadingState] = useState(false);

  useEffect(() => {
    getWFPCData();
  }, [averageTo30Degree]);

  console.log(averageTo30Degree);
  const getWFPCData = () => {
    setLoadingState(true);
    axios
      .get(`${WIND_FARM_POWER_CURVE}?averageTo30Degree=${averageTo30Degree}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${authTokens}`,
        },
      })
      .then(function (response) {
        // handle success
        let list = [];
        setTotalRowsCount(response.data.length);
        response.data.map((obj) => {
          let newObj = {};
          let applicablePeriod = obj.applicablePeriod.split("/");
          newObj["name"] = applicablePeriod[0];
          newObj["curtStat"] = "Active";
          newObj["startDate"] = applicablePeriod[0];
          newObj["endDate"] = applicablePeriod[1];
          newObj["wfpcPowerCurve"] = jsonToCsvData(obj.directionBins);
          newObj["fileName"] = newObj["startDate"].substr(0, 10) + '_' + newObj["endDate"].substr(0, 10);
          list.push(newObj);
        });
        list.sort((a, b) => {
            const sequenceA = a.startDate;
            const sequenceB = b.startDate;
            return sequenceA.localeCompare(sequenceB)
        });
        setWFPCDataFromDB(list);
        setLoadingState(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setLoadingState(false);
      });
  };
  const jsonToCsvData = (directionBins) => {
    const numberOfBins = directionBins.length;
    const degreeDelta = 360.0 / numberOfBins;
    let returnData = [["Wind speed", "Wind Direction", "", "[°]"]];
    let j = 0;
    let rowIndex = 1;
    returnData[rowIndex] = [];
    returnData[rowIndex][j++] = "[m/s]";
    for (let index = 0; index < numberOfBins; index++) {
      returnData[rowIndex][j++] = index * degreeDelta;
      const directionBin = directionBins[index];
      directionBin.speedBins.forEach((speedBin, speedIndex) => {
        const rowIndex = speedIndex + 2;
        if (returnData[rowIndex] === undefined) {
          returnData[rowIndex] = [];
          returnData[rowIndex][0] = speedBin.speedStartMS;
        }
        returnData[rowIndex][index + 1] = speedBin.power;
      });
    }
    return returnData;
  };
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    setStartIndex((pageNumber - 1) * ITEMS_COUNT_PER_PAGE);
    setEndIndex(pageNumber * ITEMS_COUNT_PER_PAGE);
  };
  return (
    <div className="UL-outer-setting">
          <h3>{t("wfpc-app.page-heading")}</h3>

      {/* TABLE OF EXISTING wfpcS */}
      <h5 className="UL-mg-l-10">{t("wfpc-app.table-heading")}</h5>
      <div
        className="UL-card-width-extralarge UL-pos-relative"
        style={{ marginLeft: "5px", maxWidth: "98.9%" }}
      >
        <div className="medium-card ul-cardHight  UL-card-height" style={{ maxHeight: "100rem" }}>
          <div className="card-body">
            <div className="ULtabs-section ul-minHeight">
              <div className="ULtab content1 tabledataR">
                <div className="Table" role="table">
                  <div className="Heading" role="rowgroup">
                    <div className="Cell" role="row">
                      <p>{t("wfpc-app.table-name")}</p>
                    </div>
                    <div className="Cell" role="row">
                      <p>{t("wfpc-app.table-status")}</p>
                    </div>
                    <div className="Cell" role="row">
                      <p>{t("wfpc-app.table-startDate")}</p>
                    </div>
                    <div className="Cell" role="row">
                      <p>{t("wfpc-app.table-endDate")}</p>
                    </div>
                    <div className="Cell" role="row">
                      <p>{t("wfpc-app.table-actions")}</p>
                    </div>
                  </div>
                  {wfpcDataFrom.length ? (
                    wfpcDataFrom
                      .slice(startIndex, endIndex)
                      .map((data, index) => (
                        <div className="table-Row" role="rowgroup" key={index}>
                          <div to="evts-overview" className="Cell" role="row">
                            <p>
                              WFPC{" "}
                              <Moment format="MMMM' YY" tz={PREFERRED_TIMEZONE}>{data.name}</Moment>
                              {
                                (startIndex === 0 && index === 0) ? <Moment format=" - YY" tz={PREFERRED_TIMEZONE}>{data.endDate}</Moment> : ""
                              }
                            </p>
                          </div>
                          <div className="Cell" role="row">
                            <p>{data.curtStat}</p>
                          </div>
                          <div className="Cell" role="row">
                            <p>
                              <Moment format="DD/MM/YYYY" tz={PREFERRED_TIMEZONE}>
                                {data.startDate}
                              </Moment>
                            </p>
                          </div>
                          <div className="Cell" role="row">
                            <p>
                              <Moment format="DD/MM/YYYY" tz={PREFERRED_TIMEZONE}>
                                {data.endDate}
                              </Moment>
                            </p>
                          </div>
                          <div className="Cell"role="row">
                            <p>
                              <CSVLink
                                data={data.wfpcPowerCurve}
                                filename={"WFPC-" + data.fileName + ".csv"}
                                className="export-wfpc"
                              >
                                {t("wfpc-app.export-wfpc")}
                              </CSVLink>
                            </p>
                          </div>
                        </div>
                      ))
                  ) : isLoadingState ? (
                    <div
                      style={{
                        position: "absolute",
                        top: "40%",
                        left: "45%",
                        width: "100%",
                      }}
                    >
                      <Loader visible={isLoadingState} />
                    </div>
                  ) : (
                    <div
                      style={{
                        position: "absolute",
                        top: "40%",
                        left: "32%",
                        width: "100%",
                      }}
                    >
                      <div>
                        {t("toast-app.no-data")}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div id="myDIV2" className="f-right">
              {wfpcDataFrom.length ? (
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={ITEMS_COUNT_PER_PAGE}
                  totalItemsCount={totalRowsCount}
                  pageRangeDisplayed={PAGE_RANGE_DISPLAYED}
                  onChange={handlePageChange.bind(this)}
                  innerClass="UL-r-0 text-right ULcltextfff UL-mg-t-35 pagination-position"
                  activeClass="active2"
                  itemClass="pagn2 f-left UL-mg-r-5"
                  linkClass="cl000"
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WFPCViewer;
