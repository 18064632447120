import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import './DropZone.css';
import axios from "axios";
import useToastContext from "../../hooks/useToastContext";
import useAuthContext from "../../hooks/useAuthContext";

const DropZone = (props) => {
    const fileInputRef = useRef();

    const { showToast, showToastSuccess, showToastError, hideToast } = useToastContext();
    const { isAdmin, authTokens } = useAuthContext();

    //console.log(isAdmin);
    const { t } = useTranslation("common");
    const dragOver = (e) => {
        e.preventDefault();
    }
    
    const dragEnter = (e) => {
        e.preventDefault();
    }
    
    const dragLeave = (e) => {
        e.preventDefault();
    }
    
    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length) {
            handleFiles(files);
        }
    }

    const filesSelected = () => {
        if (fileInputRef.current.files.length) {
            handleFiles(fileInputRef.current.files);
        }
    }

    const fileInputClicked = () => {
        fileInputRef.current.click();
    }

    const handleFiles = (files) => {
        /* const existingTokens = JSON.parse(localStorage.getItem("tokens"));
        const encodeAuthString = existingTokens && existingTokens.hasOwnProperty('encodedString') ? existingTokens.encodedString : null; */
        let isAllFilesValid = true;
        for(let i = 0; i < files.length; i++) {
            if (!validateFile(files[i])) {   
                console.log(files[i]);
                //showToastError(`File ${files[i].name} type not permitted`);
                showToastError(`${t("toast-app.file-not-permitted")}`);
                isAllFilesValid = false;
                break;
            } 
        }
        let listRequest = [];        
        if (isAllFilesValid) {
            for(let i = 0; i < files.length; i++) {
                const formData = new FormData();
                formData.append('file_data', files[i]);
                listRequest.push(
                    axios.post(props.uploadUrl, formData, {
                        headers: {
                            "Content-Type": 'application/octet-stream',
                            "Authorization": `Basic ${authTokens}`
                        }
                    })
                )
            }
            uploadMultipleFiles(listRequest);
        }        
    }

    const uploadMultipleFiles = (requests) => {
        showToast(`${t("toast-app.upload-inprogress")}`);
        axios.all(requests).then(axios.spread((...responses) => {
            hideToast();
            showToastSuccess(`${t("toast-app.upload-successfully")}`);
            props.fileUploadSuccess(true);
          })).catch(error => {
            // if there's an error, log it
            hideToast();
            if (error.response) {
                const errorCode = error.response.status;
                const errorMessage = error.response.statusText;
                showToastError(`${errorCode}: ${errorMessage}`);
                props.fileUploadSuccess(false);
            }  
          })
    }
    const validateFile = (file) => {
        const validTypes = [
            'text/csv',
            'application/vnd.ms-excel'
        ];
        return validTypes.includes(file.type) && file.size > 0;
    }

    return (
        <div>
            <div className="dropzone" id="dropzone">
                <div
                className="ULd-flex ULjustify-content-center ULalign-items-center drop-container"
                style={{ height: "100%" }}
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}
                onClick={fileInputClicked}
                >
                    <div className="ulPd5rem">
                        <strong>{t("dashboard-app.dropzone-label")}</strong>
                    </div>
                    <input
                        ref={fileInputRef}
                        className="file-input"
                        accept=".csv"
                        type="file"
                        multiple
                        onChange={filesSelected}
                    />
                </div>
            </div>
        </div>
        
      
    )
}
export default DropZone;
