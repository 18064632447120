import React, { createContext, useState } from 'react';

const AuthContext = createContext();
export default AuthContext;

export const AuthContextProvider = ({ children }) => {
  const existingTokens = JSON.parse(localStorage.getItem("tokens"));
  const encodedString = existingTokens && existingTokens.hasOwnProperty('encodedString') ? existingTokens.encodedString : null;
  const isAdminProp = existingTokens && existingTokens.hasOwnProperty('isAdmin') ? existingTokens.isAdmin : false;
  const defaultName = existingTokens && existingTokens.hasOwnProperty('name') ? existingTokens.name : 'Unknown Name';
  const defaultEmail = existingTokens && existingTokens.hasOwnProperty('email') ? existingTokens.email : 'unknown.name@ul.com';
  const [authTokens, setAuthTokens] = useState(encodedString);
  const [isAdmin, setAdmin] = useState(isAdminProp);
  const [name, setName] = useState(defaultName);
  const [email, setEmail] = useState(defaultEmail);

  const setTokens = (data) => {
    console.log(data && data.hasOwnProperty('rememberMe') && data.rememberMe)
      if (data && data.hasOwnProperty('rememberMe') && data.rememberMe) {
        localStorage.setItem("rememberMe", data.encodedString);
      } else if (data && data.hasOwnProperty('rememberMe') && !data.rememberMe) {
        localStorage.removeItem('rememberMe');
      }
      localStorage.setItem("tokens", JSON.stringify(data));
      setAuthTokens(data && data.hasOwnProperty('encodedString') ? data.encodedString : null);
      setAdmin(data && data.hasOwnProperty('isAdmin') ? data.isAdmin : false);
      setName(data && data.hasOwnProperty('name') ? data.name : defaultName);
      setEmail(data && data.hasOwnProperty('email') ? data.email : defaultEmail);
  }

  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens, isAdmin, name, email }}>
        {children}
    </AuthContext.Provider>
  )
}