import React from "react";
import { useTranslation } from "react-i18next";
import Aux from "../hoc/Auxiliary/Auxiliary";
import Intro from "../util/Intro";
import Dataset from "../components/app/dataset";

const Details = () => {
  const { t } = useTranslation("common");
  const page = "dataset";
  return (
    <Aux>
      <Intro page={page} />
      <Dataset />
    </Aux>
  );
};
export default Details;
