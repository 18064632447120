import React, { createContext, useState } from 'react';

const MonthDetailContext = createContext();
export default MonthDetailContext;

export const MonthDetailContextProvider = ({ children }) => {
    const existingCurrentData = JSON.parse(localStorage.getItem("current-month-data"));
    const currentMonthData = existingCurrentData  ? existingCurrentData : {};
    const [ monthDetailData, setMonthDetailData] = useState(currentMonthData);
    const setData = (data) => {
        localStorage.setItem("current-month-data", JSON.stringify(data));
        setMonthDetailData(data);
    }

    const default30Degree = localStorage.getItem("default30Degree");
    const [averageTo30Degree, setTo30Degree] = useState(default30Degree ? false : true)
    const set30Degree = (data) => {
        localStorage.setItem("default30Degree", data);
        setTo30Degree(data);
    }
  
    return (
      <MonthDetailContext.Provider value={{ monthDetailData, setMonthDetailData: setData, averageTo30Degree , set30Degree }}>
          {children}
      </MonthDetailContext.Provider>
    )
  }