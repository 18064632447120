import React from "react";
import { Redirect } from "react-router-dom";
import useAuthContext from "../hooks/useAuthContext";
import i18next from "i18next";

const Logout = () => {
    const { setAuthTokens } = useAuthContext();
    setAuthTokens();
    i18next.changeLanguage('en');
    localStorage.removeItem('tokens');
    return <Redirect to="/login" />;
}
export default Logout;