import jsPDF from "jspdf";
import 'jspdf-autotable';
import logo from "../../../assets/images/logo";
import { curtailmentStatus, PREFERRED_TIMEZONE } from "../../../util/Constants";
import { thousandsSeparators } from "../../../util/CommonFunctions";

const MonthlyReport = (props) => {
    let data = [];
    const options = { year: '2-digit', month: '2-digit', day: '2-digit', timeZone: PREFERRED_TIMEZONE };
    const optionsTime = {timeStyle: "short", timeZone: PREFERRED_TIMEZONE};
    props.eventDetailData.map((obj)=>{
        let newObj = {};        
          // const dates = getStartDate(obj.applicablePeriod);
          const startDate = new Date(obj.displayPorStart);
          const endDate = new Date(obj.displayPorEnd);
          //const endTime = new Date(event.getTime() + 10*60000);//new Date(event.getTime() + 10*60000);
          const ue = obj.undeliveredEnergy ? obj.undeliveredEnergy.toFixed(2) : 0;
          const ae = obj.actualEnergy ? obj.actualEnergy.toFixed(2) : 0;
          const de = obj.deemedEnergy ? obj.deemedEnergy.toFixed(2) : 0;
          newObj['ue'] = thousandsSeparators(ue);
          newObj['ae'] = thousandsSeparators(ae);
          newObj['de'] = thousandsSeparators(de);
          newObj['wind_speed'] = obj.windSpeed ? obj.windSpeed.toFixed(2) : 0;
          newObj['wind_direction'] = obj.windDirection ? obj.windDirection.toFixed(2) : 0;        
          
          //newObj['date'] = startDate.toLocaleDateString('en-GB', options)+' '+startDate.toLocaleTimeString('en', optionsTime); // AM PM
          //newObj['date'] = startDate.toLocaleDateString('en-GB', options)+' '+startDate.toLocaleTimeString('en-GB', optionsTime); //24hrs
          const startD = startDate.toLocaleDateString('en-GB', options)+' '+startDate.toLocaleTimeString('en-GB', optionsTime); //24hrs
          newObj['status'] = obj.status;
          //newObj['start_time'] = startDate.toLocaleTimeString('en', optionsTime);
          //newObj['end_time'] = endDate.toLocaleTimeString('en', optionsTime);
          //newObj['end_date'] = endDate.toLocaleDateString('en-GB', options)+' '+endDate.toLocaleTimeString('en', optionsTime); // AM PM
          //newObj['end_date'] = endDate.toLocaleDateString('en-GB', options)+' '+endDate.toLocaleTimeString('en-GB', optionsTime);  // 24hrs
          const endD = endDate.toLocaleDateString('en-GB', options)+' '+endDate.toLocaleTimeString('en-GB', optionsTime);  // 24hrs
          newObj['period'] = startD+' - '+ endD;
          data.push(newObj);
        
    });
    const energyUnit = 'kWh';
    var doc = new jsPDF();
   
    let xh, xf, xb, yb, yh, yf, n;
    let email = props.email;
    let organization = "GE";
    let country = "Germany";
    let currentDate = new Date();
    const currentDateFormat = currentDate.toLocaleDateString('en-GB', options);
    let name = props.name;
    
    let title = "Undelivered Energy and Power Curve for the Dhofar Wind Farm";
    let preparedFor = "PREPARED FOR:";
    let contactDet = "CONTACT DETAILS:";
    let dateOfIssue = "DATE OF ISSUE:";
    
    let scopeTitle = "Empowering trust through experience.";
    let lorem =
        "With over 500 experts and 35 years of experience in renewable energy we mitigate risk and navigate complexity throughout the project lifecycle and across the value chain.";
    let copyright =
        "UL and the UL logo are trademarks of UL LLC ©2021 All Rights Reserved.";
    
    let startDate = new Date(props.startDate);
    let reportHeading = 'Monthly Report of Undelivered Energy for the Dhofar Wind Farm';
    let reportMonth = new Intl.DateTimeFormat('en-US', { month: 'long'}).format(startDate);
    let reportYear = startDate.getFullYear()+' ';
    let overViewText = 'Energy and Events Overview';
    let eventPeriodsText = 'Periods of Undelivered Energy by Time and Root Cause';
    let tue = props.overviewData.undelivered_energy.split('.');
    let tae = props.overviewData.actual_energy.split('.');
    let tde = props.overviewData.deemed_energy.split('.');
    let totalUE = tue[0] +' ';
    let totalAE = tae[0] +' ';
    let totalDE = tde[0] +' ';
    
    /* page setup */
    
    xh = 110;
    yh = 60;
    n = 3;
    /*Title*/
    doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    doc.setTextColor(192, 32, 50);
    doc.text(title, xh, yh, { maxWidth: 80 });
    doc.addImage(logo, "PNG", -20, 40, 120, 120);
    /*Address*/
    let block = yh + n * 9;
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.setTextColor(10);
    doc.text(preparedFor, xh, block);
    doc.setTextColor(192, 32, 50);
    doc.setFontSize(10);
    doc.text(organization.toUpperCase(), xh, block + 6);
    doc.text(country.toUpperCase(), xh, block + 12);
    
    block = block + 21;
    doc.setTextColor(10);
    doc.setFontSize(12);
    doc.text(contactDet, xh, block);
    doc.setTextColor(192, 32, 50);
    doc.setFontSize(10);
    doc.text(name.toUpperCase(), xh, block + 6);
    doc.text(email.toUpperCase(), xh, block + 12);
    
    block = block + 21;
    doc.setTextColor(10);
    doc.setFontSize(12);
    doc.text(dateOfIssue, xh, block);
    doc.setTextColor(192, 32, 50);
    doc.setFontSize(10);
    doc.text(currentDateFormat, xh, block + 6);
    
    doc.setFontSize(15);
    doc.setFont("helvetica", "bold");
    doc.setTextColor(192, 32, 50);
    doc.text(scopeTitle, 20, yh + 130);
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.setTextColor(50);
    doc.text(lorem, 20, yh + 136, { maxWidth: 170, align: "justify" });
    
    //Generation of Footer
    xf = 20;
    yf = 260;
    
    doc.setTextColor(150);
    doc.setFontSize(11);
    doc.setFont("courier", "bold");
    //doc.text(disclaimerTitle, xf, yf);
    doc.setFontSize(9);
    doc.setFont("helvetica", "normal");
    //doc.text(disclaimerBody1, xf, yf + 5, { maxWidth: 165, align: "justify" });
    
    doc.text(copyright, 105, 287, { align: "center" });
    //footer(totalPages);
    doc.addPage();
    
    // SECOND PAGE BEGINS
    
    //Generation of header
    xh = 20;
    yh = 20;

    /*Introtext*/
    doc.setFontSize(15);
    doc.setFont("helvetica", "bold");
    doc.setTextColor(192, 32, 50);
    doc.text(reportHeading, xh, yh + 18, { maxWidth: 170, align: "justify" });

    doc.setTextColor(50);
    doc.setFontSize(16);
    doc.text(reportMonth+', '+reportYear, xh, yh + 18+10+10, { align: "justify" });
    

    doc.setTextColor(50);
    doc.setFontSize(14);
    doc.text(overViewText, xh, yh + 18+18+15, { maxWidth: 170, align: "justify" });
    
    //doc.roundedRect(xh, yh+30, 50, 25, 2, 2);
    doc.setFontSize(13);
    doc.setFont("helvetica", "bold");
    //doc.text('Total Undelivered Energy', xh, yh+35+10+15);
    doc.text('Total UE [kWh]', xh, yh+35+10+15);

    doc.setTextColor(192, 32, 50);
    doc.setFontSize(18);
    doc.setFont("helvetica", "bold");
    totalUE.length <= 7 ? doc.text(totalUE, xh+7 , yh+45+10+15, { maxWidth: 170 }) : doc.text(totalUE, xh, yh+45+10+15, { maxWidth: 170 })

    //doc.roundedRect(xh+50+10, yh+30, 50, 25, 2, 2);
    
    doc.setTextColor(50);
    doc.setFontSize(13);
    doc.setFont("helvetica", "bold");
    //doc.text('Total Actual Energy', xh+60+5, yh+35+10+15);
    doc.text('Total AE [kWh]', xh+60+5, yh+35+10+15);
    doc.setFontSize(18);
    doc.setFont("helvetica", "bold");
    //totalAE.length <= 7 ? doc.text(totalAE, xh+10+50+10, yh+45+10+15, { maxWidth: 170 }) : doc.text(totalAE, xh+10+50+3, yh+45+10+15, { maxWidth: 170 })
    totalAE.length <= 7 ? doc.text(totalAE, xh+10+50+10, yh+45+10+15, { maxWidth: 170 }) : doc.text(totalAE, xh+10+50+5, yh+45+10+15, { maxWidth: 170 })

    //doc.roundedRect(xh+50+10+50+10, yh+30, 50, 25, 2, 2);
    doc.setFontSize(13);
    doc.setFont("helvetica", "bold");
    //doc.text('Total Deemed Energy', xh+120+5, yh+35+10+15);
    doc.text('Total DE [kWh]', xh+120+5, yh+35+10+15);
    doc.setFontSize(18);
    doc.setFont("helvetica", "bold");
    //totalDE.length <= 7 ? doc.text(totalDE, xh+5+50+25+50, yh+45+10+15, { maxWidth: 170 }) : doc.text(totalDE, xh+5+50+25+40+3, yh+45+10+15, { maxWidth: 170 })
    totalDE.length <= 7 ? doc.text(totalDE, xh+5+50+25+50, yh+45+10+15, { maxWidth: 170 }) : doc.text(totalDE, xh+5+50+25+40+5, yh+45+10+15, { maxWidth: 170 })

    doc.setTextColor(50);
    doc.setFontSize(12);
    doc.text(eventPeriodsText, xh, yh + 45+10+10+25, {align: "justify" });
    const pageMargin = 20 // NEW CODE
    const pageDimensions = { // NEW CODE
        height: 297,
        width: 210
      }
    const liveArea = {  
        width: pageDimensions.width - pageMargin,
        height: pageDimensions.height - pageMargin
      }
    const padding = 10
    
    const headers = [
        //{key: 'date', label: 'Start Date'},
        //{key: 'status', label: 'Root Cause'},
        //{key: 'start_time', label: 'Start Time'},
        //{key: 'end_time', label: 'End Time'},
        //{key: 'end_date', label: 'End Date'},
        {key: 'period', label: 'Period'},
        {key: 'status', label: 'Root Cause'},
        {key: 'ue', label: 'UE [kWh]'},
        {key: 'de', label: 'DE [kWh]'},
        {key: 'ae', label: 'AE [kWh]'},
        {key: 'wind_speed', label: 'WS [m/s]'},
        {key: 'wind_direction', label: 'WD [°]'},
    ]
    const xPositions = [];
    doc.setFontSize(10);
    headers.forEach((heading, index) => {
        // If you decide to add xPos to the header mappings this will fire
        if (heading.hasOwnProperty('xPos')) {
          doc.text(String(heading.label), heading.xPos, pageMargin)
          xPositions.push(heading.xPos)
        } else {
          // get x position for heading
          const xPositionForCurrentHeader = pageMargin + index * (liveArea.width/(headers.length))
          // need to position our text within our live area 
          const yPositionForHeaders = pageMargin + 100;
          //console.log(index === 0 ? xPositionForCurrentHeader:(xPositionForCurrentHeader + padding))
          if (index === 1) {
            doc.text(String(heading.label), xPositionForCurrentHeader+ 5, yPositionForHeaders)
            // Push x position of current header to xPositions array
            xPositions.push(xPositionForCurrentHeader + 5)
          } else if (index === 2) {
            doc.text(String(heading.label), xPositionForCurrentHeader + padding - 3, yPositionForHeaders)
            xPositions.push(xPositionForCurrentHeader + padding-3)
          } else if ( index === 3) {
            doc.text(String(heading.label), xPositionForCurrentHeader + padding - 3, yPositionForHeaders)
            xPositions.push(xPositionForCurrentHeader + padding-3)
          } else if (index === 4) {
            doc.text(String(heading.label), xPositionForCurrentHeader + padding - 3, yPositionForHeaders)
            xPositions.push(xPositionForCurrentHeader + padding-3)
          }  else if (index === 5) {
            doc.text(String(heading.label), xPositionForCurrentHeader + 5 , yPositionForHeaders)
            xPositions.push(xPositionForCurrentHeader + 8)
          } else if (index === 6) {
            doc.text(String(heading.label), xPositionForCurrentHeader, yPositionForHeaders)
            xPositions.push(xPositionForCurrentHeader)
          } else {
            doc.text(String(heading.label), index === 0 ? xPositionForCurrentHeader:(xPositionForCurrentHeader - padding), yPositionForHeaders)
            xPositions.push(index === 0 ? xPositionForCurrentHeader:(xPositionForCurrentHeader - padding))
          }
        }
      });
      // ROWS
      doc.line(pageMargin, pageMargin+ 100 + 3.5, liveArea.width +  3 , pageMargin+ 100 + 3.5);
      doc.setFont("helvetica", "normal");
      doc.setTextColor(50);
      doc.setFontSize(10);
      const baseYPosForRows = pageMargin + padding;
      let nextYPos = baseYPosForRows + 100;
      data.forEach((row, rIndex) => {
        const rowHeights = [];
        // COLUMNS
        headers.forEach((column, cIndex) => {
            const maxLength = xPositions[cIndex] - (xPositions[cIndex !== 0 && cIndex - 1] ? xPositions[cIndex !== 0 && cIndex - 1] : 0);
            let longText = doc.splitTextToSize(String(row[column.key]), maxLength + 10);
            if (cIndex == 1) {
                let status = curtailmentStatus.filter((obj)=>{
                    if (obj.key === longText[0]) {
                        return obj.label
                    }
                });
                
                if (status.length > 0) {
                    longText = status[0].label;
                    longText = doc.splitTextToSize(longText, 45 );
                }
            }
            const rowHeight = doc.getLineHeightFactor()
            //console.log(rowHeight, doc.getLineHeightFactor());
            rowHeights.push(rowHeight);
            doc.text(longText, xPositions[cIndex], nextYPos)
        });

        nextYPos = nextYPos + Math.max(...rowHeights, 10)

        if (nextYPos > liveArea.height) {
            doc.addPage();
            //footer(totalPages);
            nextYPos = baseYPosForRows
        }

      });

    //Generation of Footer
    doc.setFont("helvetica", "normal");
    doc.addPage();
    let finalY = doc.lastAutoTable.finalY || 10
    doc.autoTable({
      theme:'grid',
      headStyles: { fillColor: [192, 32, 50] },
      bodyStyles: { textColor: 50},
      margin: { left: 20, right:20 },
      startY: finalY+20,
      head: [['Abbreviation', 'Value', 'Energy ['+energyUnit+']']],
      body: [
        ['Oprtr Shut', 'Planned shutdown by grid operator', props.total_oprtr_shut],
        ['Oprtr Curt', 'Planned curtailment by grid operator', props.total_oprtr_curt],
        ['Owner Shut', 'Planned shutdown by wind farm owner', props.total_owner_shut],
        ['Owner Curt', 'Planned curtailment by wind farm owner', props.total_owner_curt],
        ['Unpln Shut', 'Unplanned shutdown', props.total_unpln_shut],
        /* ['UE', 'Undelivered Energy'],
        ['AE', 'Actual Energy'],
        ['DE', 'Deemed Energy'],
        ['WS', 'Wind Speed'], */
        [{content:'UE'}, {content:'Undelivered Energy', colSpan: 2}],
        [{content:'AE'}, {content:'Actual Energy', colSpan: 2}],
        [{content:'DE'}, {content:'Deemed Energy', colSpan: 2}],
        [{content:'WS'}, {content:'Wind Speed', colSpan: 2}],
        [{content:'WD'}, {content:'Wind Direction', colSpan: 2}],
      ],
    })

    var pageCount = doc.internal.getNumberOfPages(); //Total Page Number
    for(let i = 2; i <= pageCount; i++) { 
        doc.setPage(i); 
        let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber; //Current Page
        doc.setFontSize(11);
        doc.setTextColor(150);
        doc.text('page: ' + pageCurrent + '/' + pageCount, xh+150, 287);

        /*Title*/
        //if (i > 1) {
            doc.setFontSize(11);
            doc.setTextColor(150);
            doc.text(title, xh, yh, { maxWidth: 170 });
            doc.text(currentDateFormat, xh + 150, yh);
        //}
        
    }

    let reportName = "Report_" + reportMonth + "_" + reportYear;
    
    //DOWNLOAD PDF
    doc.save(reportName.concat(".pdf"));
}

export default MonthlyReport;
