import React from "react";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import Layout from '../hoc/Layout/Layout';
import Login from '../containers/Auth/Login/Login';
import SignUp from '../containers/Auth/SignUp/SignUp';
import ForgotPassword from '../containers/Auth/ForgotPassword/ForgotPassword';
import NotFound from '../containers/NotFound/NotFound';
import Wfpc from '../containers/wfpc';
import Logout from '../containers/Logout';
import Dashboard from '../containers/Dashboard/Dashboard';
import MyAccount from '../containers/MyAccount/MyAccount';
import PrivateRoute from './PrivateRoute';
import DataSet from '../containers/Details';
import FilesViewer from "../containers/Files/Files";

const routes = [
    {
        exact: true,
        path: "/",
        component: Login
    },
    {
        path: "/login",
        component: Login
    },
    {
        path: "/sign-up",
        component: SignUp
    },
    {
        path: "/forgot-password",
        component: ForgotPassword
    },
    /* {
      path: "/link1",
      component: Link1,
      routes: [
        {
          path: "/link1/child1l1",
          component: Link1C1,
          exact: true,
        },
        {
          path: "/link1/child2l1",
          component: Link1C2,
          exact: true,
        }
      ]
    }, */
    {
        private: true,
        path: "/dashboard",
        component: Dashboard
    },
    {
        private: true,
        path: "/view-wfpc",
        component: Wfpc
    },
    {
        private: true,
        path: "/dataset",
        component: DataSet
    },
    {
        private: true,
        path: "/my-account",
        component: MyAccount
    },
    {
        private: true,
        path: "/logout",
        component: Logout
    },
    {
        private: true,
        path: "/files",
        component: FilesViewer
    },
    {
        path: "*",
        component: NotFound
    }
  ];


const Routes = (props) => {
    //const { authTokens } = useAuth();
    return <Router>
                <Layout>
                    <Switch>
                        {routes.map((route, i) => (
                            route.private ? <PrivateRoute key={i} path={route.path} component={route.component} /> : <RouteWithSubRoutes key={i} {...route} />
                        ))}
                    </Switch>                        
                </Layout>
    </Router>
}

const RouteWithSubRoutes = (route) => {
    return (
        <Route
          path={route.path}
          render={props => (
            // pass the sub-routes down to keep nesting
            <route.component {...props} routes={route.routes} />
          )}
        />
      );
}
export default Routes;
