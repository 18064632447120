import React from "react";
import "./ForgotPassword.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from 'yup';
import Aux from '../../../hoc/Auxiliary/Auxiliary';
import { Link} from 'react-router-dom';
import {useTranslation} from "react-i18next";

const ForgotPassword = () => {
        const {t, i18n} = useTranslation('common');
        return (
           
                <Formik
                initialValues={{
                    email: ''
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string()
                        .email('Email is invalid')
                        .required('Email is required')
                })}
                onSubmit={fields => {
                    alert('SUCCESS!! :-)\n\n' + JSON.stringify(fields, null, 4))
                }}
                render={({ errors, status, touched }) => (
                    <Aux>
                        <div className="UL-login-text ulError-color">{t('forms.forgot-password-link')}</div>
                        <Form className="UL-padding43rem">                        
                            <div className="form-group  UL-mb-6">
                                <label htmlFor="email" className="label-name dIB">{t('forms.email')}</label>
                                <Field name="email" placeholder={t('forms.email-placeholder')} type="text" className={'UL-form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                <ErrorMessage name="email" component="div" className="ulError-color" />
                            </div>                        
                            <button type="submit" className="ULbtn btn-ul-red w100 cr-pointer">{t('forms.submit-button')}</button>
                            <div className="UL-signup-text"><span> <Link to="/login" className="ulError-color">{t('forms.log-in')}</Link> </span> </div>
                        </Form>
                    </Aux>
                    
                )}
            />
               
        );
}
export default ForgotPassword;