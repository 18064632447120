import React, { useState, useEffect, useRef } from "react";
import { Steps, Hints } from "intro.js-react";
import { useTranslation } from "react-i18next";
import Aux from "../hoc/Auxiliary/Auxiliary";

const Intro = (props) => {
  const { t } = useTranslation("common");
  let hintRef = React.useRef();
  const introTourVisited = localStorage.getItem(props.page); //check from localstorage
  //let introTourVisited = tourVisited; // check from db if required
  const [stepsEnabled, setStepsEnabled] = useState(
    introTourVisited ? false : true
  );
  const [hintsEnabled, setHintsEnabled] = useState(true);
  const [initialStep, setInitialStep] = useState(0);

  useEffect(() => {
    var hintIds = JSON.parse(
      localStorage.getItem(props.page + "-hintIds") || "[]"
    );
    hintIds.forEach(function (hintId, index) {
      hintRef.introJs.hideHint(hintId);
    });
    console.log(currentPageHints);
  }, []);

  const introductionData = {
    dashboard: {
      steps: [
        {
          element: ".dropzone",
          intro: t("intro.dashboard-dropzone"),
        },
        {
          element: ".datatable",
          intro: t("intro.dashboard-datatable"),
        },
        {
          element: ".UL-pagination",
          intro: t("intro.dashboard-pagination"),
        },
      ],
      hints: [],
    },
  };

  const onExit = () => {
    setStepsEnabled(false);
  };

  const onComplete = () => {
    localStorage.setItem(props.page, true);
  };

  const onHintClose = (id) => {
    let hintIds = JSON.parse(
      localStorage.getItem(props.page + "-hintIds") || "[]"
    );
    if (hintIds && hintIds.indexOf(id) != -1) {
      return;
    }
    let hintId = id;
    hintIds.push(hintId);
    localStorage.setItem(props.page + "-hintIds", JSON.stringify(hintIds));
  };

  const onHintClicked = () => {
    //console.log(hintId.getAttribute("data-step"));
  };
  const setOptions = {
    doneLabel: t("intro.done-button"),
    skipLabel: t("intro.skip-button"),
    nextLabel: t("intro.next-button"),
    prevLabel: t("intro.back-button"),
    showStepNumbers: true,
    exitOnOverlayClick: true,
  };

  const setHintOptions = {
    hintButtonLabel: t("intro.hint-button"),
  };

  let currentPageSteps = [];
  let currentPageHints = [];
  for (let data in introductionData) {
    if (data === props.page) {
      currentPageSteps = introductionData[data].hasOwnProperty("steps")
        ? introductionData[data].steps
        : [];
      currentPageHints = introductionData[data].hasOwnProperty("hints")
        ? introductionData[data].hints
        : [];
      break;
    }
  }

  return (
    <Aux>
      <Steps
        enabled={(currentPageSteps ? true : false) && stepsEnabled}
        steps={currentPageSteps}
        showProgress={true}
        initialStep={initialStep}
        onExit={() => onExit()}
        onComplete={() => onComplete()}
        options={setOptions}
      />

      <Hints
        enabled={(currentPageHints ? true : false) && hintsEnabled}
        hints={currentPageHints}
        onClose={(id) => onHintClose(id)}
        onClick={() => onHintClicked()}
        options={setHintOptions}
        ref={(hints) => (hintRef = hints)}
      />
    </Aux>
  );
};

export default Intro;
