import React, {useEffect, useState} from 'react';
import NavigationItemsDrawer from '../NavigationItems/NavigationItemsDrawer';
import Backdrop from '../../UI/Backdrop/Backdrop';
import Aux from '../../../hoc/Auxiliary/Auxiliary';
import './SideDrawer.css';

const SideDrawer = (props) => {
    const [isCheckbox, setCheckbox] = useState(false);
    const [windowWidthFlag, setWindowWidth] = useState(false);
    useEffect(() => {
        if (window.innerWidth <= 1028) {
           setCheckbox(true);
           setWindowWidth(true);
        }
    }, []);

    return (
        <Aux>
            <Backdrop show={props.open} clicked={props.closed}/>
            
                <input id="slide-sidebar" type="checkbox" role="button" checked={isCheckbox} onClick={() => setCheckbox(!isCheckbox)}/>
                <label htmlFor="slide-sidebar" className="UL-hambargarmenu UL-pos-absolute" style={(windowWidthFlag && isCheckbox) ? {marginLeft:'0px', left:'20px'} : {}}><span><i className="fas fa-bars fs2x"></i></span></label>                
                <NavigationItemsDrawer />
        </Aux>        
    );
}

export default SideDrawer;