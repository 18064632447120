import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {  I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { hotjar } from "react-hotjar";
import common_en from "../src/translations/en/common.json";
import common_de from "../src/translations/de/common.json";
import "intro.js/introjs.css";
import 'react-toastify/dist/ReactToastify.min.css';
import './index.css';
//import 'bootstrap/dist/css/bootstrap.css';
//import 'react-flags-select/css/react-flags-select.css';
//import "intro.js/introjs-rtl.css";

let language = localStorage.getItem('selected-language') ? localStorage.getItem('selected-language') : 'en';
i18next.init({
  interpolation: { escapeValue: false },
  lng: language,                              // language to use
  resources: {
      en: {
          common: common_en               // 'common' is our custom namespace
      },
      de: {
          common: common_de
      },
  },
});
hotjar.initialize("2191303", "6");

ReactDOM.render(
  <React.StrictMode>
      <I18nextProvider i18n={i18next}>              
          <App />
      </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
