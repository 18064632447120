import React, {Component} from 'react';
import { NavLink } from 'react-router-dom';
import './NavigationItems.css';
import NavigationItem from '../NavigationItem/NavigationItem';
import { APPLICATION_NAME } from '../../../util/Constants';
import NavMenus  from '../../../util/NavMenus.json';
import LanguageSelector from '../../LanguageSelector/LanguageSelector';

class NavigationItemsDrawer extends Component {
    
    constructor(props) {
        super(props)
    }

    state = {
        tabState : {}
    }
    
    toggleExpanded = (e, tabTitle, parentTitle, grandParentTitle) => {
        this.setState(prevSate => {
          return {
              tabState :{
                ...prevSate.tabState,
                [tabTitle]: !this.state.tabState[tabTitle]
              }
          }
        });

        NavMenus.NavMenusData.filter((obj) => {
            if (`menu-${obj.id}` == parentTitle || `menu-${obj.id}` == grandParentTitle) {
                return false;
            }
            return true;
        }).map((obj, index) => {            
            if (`menu-${obj.id}` != tabTitle) {
                this.setState(prevSate => {
                  return {
                      tabState :{
                        ...prevSate.tabState,
                        [`menu-${obj.id}`]: false
                      }
                  }
                });
            }
        });
    }
    navMenuSections = (sections, parentId, grandParentId='') => {
        return sections.map((section, i) => {
            return <ul key={i} className   ="collapse list-unstyled">
                        <li>
                            <a data-toggle="collapse" aria-expanded="true">   
                            <span className="lbText UL-sidebar-Hcolor UL-pd-b-10">          
                                {section.label}
                            </span>
                            </a>                            
                            {this.navMenuChildren(section.children, parentId, grandParentId)}                            
                        </li>
                    </ul>
        });
    }
    
    navMenuChildren = (childrens, parentId, grandParentId='') => {
        return childrens.map((menu, i) => {
            return  <ul className="UL-pd-l-15" key={i}>
                        <li className={`${ this.state.tabState[`menu-${menu.id}`] ? 'active': ""}`} onClick={(e) => {console.log(`menu-${menu.id}`);e.stopPropagation(); this.toggleExpanded (e, `menu-${menu.id}`, `menu-${parentId}`, `${menu.hasOwnProperty("grandParent") ? `menu-${menu.grandParent}` : ''}`) }}>
                            <NavLink to={menu.link} className="lbText fw-n cl000 UL-Tdecoration UL-pd7">
                                { menu.label }
                                <span className="UL-wd-100p UL-mg-l-5" style={{display:'inline'}}></span>
                                {
                                    menu.hasOwnProperty("sections") && menu.sections.length ||
                                    menu.hasOwnProperty("children") && menu.children.length
                                    ?   <i className={`fas fa-angle-${this.state.tabState[`menu-${menu.id}`]? 'down':'right'} UL-pos-relative UL-r-10 UL-t-0 UL-mg-t-6 f-right`}></i>
                                    : null
                                }                                
                            </NavLink>
                            <div className="UL-pd-l-40 UL-pd-t-15 UL-sidenavformate">
                            {                                
                                menu.hasOwnProperty("sections") && menu.sections.length ? this.navMenuSections(menu.sections, menu.id, menu.hasOwnProperty("grandParent") ? menu.grandParent : '') : 
                                                                                            menu.hasOwnProperty("children") && menu.children.length ? this.navMenuChildren(menu.children, menu.id, menu.hasOwnProperty("grandParent") ? menu.grandParent : '') : null
                            } 
                            </div>                                                               
                        </li>
                    </ul>
                    
        })
    }
    
    navMenuhandler = () => {
        return NavMenus.NavMenusData.map((menu, i) => {              
            if (menu.checkAuth)  {    
                return <NavigationItem 
                    key={i}
                    drawer = {menu.drawer}
                    isLanding = {menu.isLanding}
                    link={menu.link} 
                    label = {menu.label}
                    id={menu.id} 
                    display = {menu.display}
                    MobileOnly = {menu.mobileOnly}
                    margin={menu.margin}
                    icon={menu.icon}
                    children={menu.children}
                    sections={menu.sections.length}
                    onClickEvent = {(e, active, id) => this.toggleExpanded(e, `menu-${id}`)}
                    active={this.state.tabState[`menu-${menu.id}`]}
                    data = {NavMenus.NavMenusData}
                    reference = {node => this.node = node}
                    >
                        {this.navMenuSections(menu.sections, menu.id)}
                </NavigationItem>
            }
        })
    }
    render() {
        return (
            <div className="UL-sidebar f-left UL-pos-absolute" style={{height:'auto', padding:0, top:0}}>
                <ul className="ul-sidebar-height">
                    <li className="ULd-flex">
                        <div className="ULlogo">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <title>UL Logo</title>
                                <path d="M10,20C4.5,20,0,15.5,0,10C0,4.5,4.5,0,10,0c5.5,0,10,4.5,10,10C20,15.5,15.5,20,10,20 M10,18.1c4.5,0,8.1-3.6,8.1-8.1 c0-4.5-3.6-8.1-8.1-8.1c-4.5,0-8.1,3.6-8.1,8.1C1.9,14.5,5.5,18.1,10,18.1"></path>
                                <path d="M5.1,4.6H7v5.9c0,0.4,0.1,0.7,0.6,0.7c0.5,0,0.6-0.4,0.6-0.8V4.6h1.8v5.7c0,1.3-0.6,2.4-2.5,2.4c-1.6,0-2.5-0.7-2.5-2.4 V4.6"></path>
                                <polyline points="10.8,15.4 10.8,7.5 12.7,7.5 12.7,13.8 14.9,13.8 14.9,15.4 10.8,15.4"></polyline>
                            </svg>
                        </div>
                        <div className="appname align-self pdl10">{  APPLICATION_NAME  }</div>
                    </li>
                    {
                        this.navMenuhandler()
                    }
                </ul>
                <div className="UL-pos-absolute UL-r-20 UL-b-20 menuflag">
                   <LanguageSelector optionLable={true} class="menu-flags"/>
                </div>
            </div>
            
        )
    }
    
    

}

export default NavigationItemsDrawer;