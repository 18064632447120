import React from 'react';
import Aux from '../Auxiliary/Auxiliary';
import Menubar from '../../components/Navigation/Menubar/Menubar';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';
import './Layout.css';
import useAuthContext from "../../hooks/useAuthContext";
import { APPLICATION_NAME } from '../../util/Constants';
import {useTranslation} from "react-i18next";
import LanguageSelector from '../../components/LanguageSelector/LanguageSelector';
import CookieConsent from "react-cookie-consent";
import useThemeContext from "../../hooks/useThemeContext";

const Layout = (props) => {
    const {themeMode, setThemeMode} = useThemeContext();
    const {t} = useTranslation('common');
    const { authTokens  } = useAuthContext();
    const cookieConsentLayer = (
        <CookieConsent
        sameSite="strict"
        buttonText="Accept"
        style={{
          background: "#c02032",
          paddingLeft: "10%",
          paddingRight: "10%",
        }}
        buttonStyle={{
          background: "#DB2C2C",
          borderRadius: "2px",
          padding: "5px",
          color: "white",
          fontWeight: "bolder",
        }}
      >
        <h4>Cookies on UL</h4>{" "}
        <p style={{color:"#ffffff"}}>
          We use cookies to personalize content and ads, to provide social media
          features and to analyze our traffic. We also share information about
          your use of our site with our social media, advertising and analytics
          partners.
          <a
            href="https://www.ul.com/customer-resources/online-policies/about-cookies"
            target="_blank"
            rel="noreferrer"
            style={{ color: "white", paddingLeft: "10px" }}
          >
            About our Cookies
          </a>
        </p>
      </CookieConsent>
    )
     return authTokens ?
            (
                <Aux>
                    <div className={`ul-wrapper UL-pos-relative ${themeMode}`}>
                        <Menubar/>
                        <div className="main-wrap ULoverflowAuto-y UL-wd-100p pd10">
                            <SideDrawer/>
                            <main className="UL-contantright pd0 ULoverflow-hidden">
                                { props.children }
                            </main>
                        </div>                    
                    </div>
                    {cookieConsentLayer}
                </Aux>
            )
            :
            (
                <Aux>
                    <div className="centerDiv">
                        <div className="UL-innerlogin">
                            <div className="UL-div1">
                                <div className="UL-plateform-text">{ APPLICATION_NAME }</div>
                                <div className="UL-plateform-subtext">{t('welcome.title')}</div>
                                <div className="UL-login-Image"></div>
                            </div>
                            <div className="UL-div2">
                                <div style={{float:'right'}}>
                                    <LanguageSelector optionLable={true} class="menu-flags-login"/>
                                </div>
                                <div className="UL-logo">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <title>UL Logo</title>
                                    <path d="M10,20C4.5,20,0,15.5,0,10C0,4.5,4.5,0,10,0c5.5,0,10,4.5,10,10C20,15.5,15.5,20,10,20 M10,18.1c4.5,0,8.1-3.6,8.1-8.1 c0-4.5-3.6-8.1-8.1-8.1c-4.5,0-8.1,3.6-8.1,8.1C1.9,14.5,5.5,18.1,10,18.1"></path>
                                    <path d="M5.1,4.6H7v5.9c0,0.4,0.1,0.7,0.6,0.7c0.5,0,0.6-0.4,0.6-0.8V4.6h1.8v5.7c0,1.3-0.6,2.4-2.5,2.4c-1.6,0-2.5-0.7-2.5-2.4 V4.6"></path>
                                    <polyline points="10.8,15.4 10.8,7.5 12.7,7.5 12.7,13.8 14.9,13.8 14.9,15.4 10.8,15.4"></polyline>
                                    </svg>
                                </div>                                
                                { props.children }
                            </div>
                        </div>
                    </div>
                    {cookieConsentLayer}
                </Aux>
            )
}

export default Layout;