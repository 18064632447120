import React from "react";
import { useTranslation } from "react-i18next";
import Aux from "../hoc/Auxiliary/Auxiliary";
import Intro from "../util/Intro";
import WFPCViewer from "../components/app/wfpc-overview";

const WFPC = () => {
  const { t } = useTranslation("common");
  const page = "link2";
  return (
    <Aux>
      <Intro page={page} />
      <WFPCViewer />
    </Aux>
  );
};
export default WFPC;
